import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "14px",
		boxShadow: "12px 24px 34px #00000029;",
		padding: 0,
		width: "550px",
	},
	message: {
		fontSize: 16,
		lineHeight: "20px",
		padding: "24px",
		width: "100%",
	},
	info: {
		background: "#E8F4FD",
		color: "#2755A9",
	},
	error: {
		color: "#833A5D",
		background: "#F8E8EF",
	},
	success: {
		color: "#2D6925",
		background: "#EAF6EA",
	},
	circle: {
		color: "#fff",
	},
	children: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	childrenText: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: 14,
	},
});
