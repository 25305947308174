import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { Box, Fade, List, ListItem, ListItemIcon, Modal, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "app/themes";
import clsx from "clsx";
import { Fragment } from "react";
import navigateTo from "shared/helpers/navigateTo";
import { isAuthorized } from "shared/hooks/usePermission";
import { useUser } from "shared/hooks/useUser";
import { ISidebarItem, ISidebarSection } from "shared/models/Sidebar";
import { resetSelectedStudent } from "../Slices/selectedStudentSlice";
import { handleLogout } from "app/auth/authService";
import { useDispatch } from "react-redux";
import { RQKeys } from "shared/react-query/globalKeys";
import { getUser } from "shared/services/userService";
import { useQueryClient } from "@tanstack/react-query";
import { getRoutesByRole } from "shared/helpers/getRoutesByRole";

type MenuNavigationProps = {
	open: boolean;
	sections: ISidebarSection[];
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuNavigation = ({ open, sections, setOpen }: MenuNavigationProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { user } = useUser();
	if (!user.isLoggedIn) return <></>;
	const client = useQueryClient();
	const profileRoute = getRoutesByRole(user.claims.userRole).VIEW_PROFILE;
	const filterForItems = ({ neededPermissions, neededRole, neededStudentTypes }: ISidebarItem) =>
		(!neededPermissions && !neededRole && !neededStudentTypes) ||
		isAuthorized(user, {
			neededPermissions,
			neededRole,
			permissionOperator: "Or",
			neededStudentTypes,
		});

	const handleProfileClick = (e) => {
		if (user.isLoggedIn) {
			client.prefetchQuery([...RQKeys.USER, user.id], getUser);
			navigateTo(profileRoute);
		}
		setOpen(false);
	};

	const renderMenuItems = (items: ISidebarItem[]) => (
		<List>
			{items.filter(filterForItems).map((item, index) => (
				<ListItem
					button
					key={`nav-item-${item.text}-${index}`}
					onClick={() => {
						if (location.pathname !== item.route) navigateTo(item.route);
						setOpen(false);
					}}
					className={clsx(classes.listItem, {
						[classes.listItemActive]: location.pathname === item.route,
					})}
					data-test-id={`sidebar-item-${item.text}`}
				>
					<ListItemIcon
						className={clsx(
							classes.itemIcon,
							{
								[classes.itemIconActive]: location.pathname === item.route,
							},
							{ [classes.notificationsItemIcon]: item.text === "Notifications" }
						)}
					>
						{item.icon}
					</ListItemIcon>
					<div className={classes.itemLabel}>{item.text}</div>
				</ListItem>
			))}
			<span className={classes.currentIndicator} />
		</List>
	);

	return (
		<Modal className={classes.container} open={open} hideBackdrop={true} closeAfterTransition>
			<Fade in={open}>
				<Box className={classes.containerInside}>
					<Box style={{ position: "absolute", width: "100%", height: "100%", display: "grid" }}>
						<List className={classes.topList}>
							{sections
								.map((section) => ({
									...section,
									items: section.items.filter(filterForItems),
								}))
								.filter((section) => section.items.length > 0)
								.map((section: ISidebarSection) => (
									<Fragment key={`sidebar-${section.name}`}>
										<div className={classes.sectionHeader}>{section.name}</div>
										{renderMenuItems(section.items)}
									</Fragment>
								))}
						</List>
						{user.isLoggedIn && (
							<div style={{ alignSelf: "end", marginBlock: "10px" }}>
								<ListItem
									button
									key={`nav-item-profile`}
									onClick={handleProfileClick}
									className={classes.listItem}
									data-test-id={`sidebar-item-profile`}
								>
									<ListItemIcon className={classes.itemIcon}>
										<AccountCircle />
									</ListItemIcon>
									<div className={classes.itemLabel}> My Profile </div>
								</ListItem>
								<ListItem
									button
									key={`nav-item-profile`}
									onClick={() => {
										dispatch(resetSelectedStudent());
										handleLogout();
									}}
									className={classes.listItem}
									data-test-id={`sidebar-item-profile`}
								>
									<ListItemIcon className={classes.itemIcon}>
										<ExitToApp />
									</ListItemIcon>
									<div className={classes.itemLabel}> Logout </div>
								</ListItem>
							</div>
						)}
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

const useStyles = makeStyles(({ palette }: Theme) => ({
	containerInside: { display: "flex", flexDirection: "column", overflow: "auto", maxHeight: "100%" },
	container: {
		position: "absolute",
		backgroundColor: palette.text.primary,
		top: "58px",
		overflow: "auto",
	},
	listItem: {
		color: "#7F8285",
		opacity: 0.75,
		transition: "color .3s, opacity .3s, background-color .3s",
		padding: "8px 22px",
		height: "42px",
		gap: "10px",
		fontSize: "18px",
	},
	listItemActive: {
		color: `${palette.common.white} !important`,
		opacity: 1,
		"& ~ $currentIndicator": {
			opacity: 1,
			transition: "opacity .4s, top .3s ease-in-out",
		},
		"&:nth-child(1) ~ $currentIndicator": {
			top: "16px",
		},
		"&:nth-child(2) ~ $currentIndicator": {
			top: "56px",
		},
		"&:nth-child(3) ~ $currentIndicator": {
			top: "96px",
		},
		"&:nth-child(4) ~ $currentIndicator": {
			top: "137px",
		},
		"&:nth-child(5) ~ $currentIndicator": {
			top: "176px",
		},
		"&:nth-child(6) ~ $currentIndicator": {
			top: "214px",
		},
	},
	itemIcon: {
		color: "#7F8285 !important",
		minWidth: "unset",
		transition: "color .3s",
		"&>svg": {
			width: "25px !important",
			height: "25px !important",
		},
	},
	notificationsItemIcon: {
		color: "#7F8285 !important",
		minWidth: "unset",
		transition: "color .3s",
		"&>span>svg": {
			width: "25px !important",
			height: "25px !important",
			marginTop: "-3px",
		},
		"&>span>span": {
			padding: "0px",
			minWidth: "18px !important",
			height: "18px !important",
			fontSize: "12px",
		},
	},
	itemIconActive: {
		color: `${palette.common.white} !important`,
	},
	itemLabel: {
		height: "22px",
	},
	sectionHeader: {
		padding: "7px 22px",
		fontSize: 14,
		color: `${palette.common.white} !important`,
		fontFamily: "WorkSans",
		fontWeight: 500,
		lineHeight: "14px",
	},
	currentIndicator: {
		opacity: 0,
		background: palette.common.white,
		height: "25px",
		width: "4px",
		position: "absolute",
		borderRadius: "0px 4px 4px 0px",
	},
	topList: {
		paddingTop: "0px",
	},
}));

export default MenuNavigation;
