import { UserRoles } from "app/auth/UserRoles";

export const getRoleDisplayName = (role: UserRoles | "none" | undefined) => {
	switch (role) {
		case UserRoles.Admin:
			return "Admin";
		case UserRoles.SparkMember:
			return "Spark Member";
		case UserRoles.Student:
			return "Student";
		case UserRoles.LegalGuardian:
			return "Parent";
		default:
			return "";
	}
};
