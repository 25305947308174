// Icons
import UsersIcon from "@mui/icons-material/AccountCircle";
import CoursesIcon from "@mui/icons-material/AutoStories";
import SyncIcon from "@mui/icons-material/CloudSync";
import AssignmentsIcon from "@mui/icons-material/MenuBook";
import StudentsIcon from "@mui/icons-material/School";
import ConfigurationsIcon from "@mui/icons-material/Tune";
import AssessmentsIcon from "@mui/icons-material/WorkspacePremium";

import { UserRoles } from "app/auth/UserRoles";
import { SparkMemberRoutes } from "app/routes/routes";
import { PERMISSIONS } from "shared/constants/enums/Permissions";
import { ISidebarContent } from "shared/models/Sidebar";
import DyanmicNotificationsItem from "../NotificationsItem";

export const sparkMemberSidebarContent: ISidebarContent = {
	sections: [
		{
			name: "Management",
			items: [
				{
					text: "Members",
					route: SparkMemberRoutes.VIEW_USERS,
					icon: <UsersIcon />,
					neededPermissions: [PERMISSIONS.Member.View],
				},
				{
					text: "Students",
					route: SparkMemberRoutes.VIEW_STUDENTS,
					icon: <StudentsIcon />,
					neededPermissions: [PERMISSIONS.Student.View],
				},
				{
					text: "Courses",
					route: SparkMemberRoutes.VIEW_COURSES,
					icon: <CoursesIcon />,
					neededPermissions: [PERMISSIONS.Courses.ViewPublished, PERMISSIONS.Courses.ViewDraft],
				},
				{
					text: "Assignments",
					route: SparkMemberRoutes.VIEW_ASSIGNMENTS,
					icon: <AssignmentsIcon />,
					neededPermissions: [PERMISSIONS.Assignments.View],
				},
				{
					text: "Reports",
					route: SparkMemberRoutes.VIEW_ASSESSMENTS,
					icon: <AssessmentsIcon />,
					neededPermissions: [PERMISSIONS.Assessments.View],
				},
				{
					text: "Notifications",
					route: SparkMemberRoutes.VIEW_NOTIFICATIONS,
					icon: <DyanmicNotificationsItem />,
				},
			],
		},
		{
			name: "Administrative",
			items: [
				{
					text: "Configurations",
					route: SparkMemberRoutes.VIEW_CONFIGURATIONS,
					icon: <ConfigurationsIcon />,
					neededRole: UserRoles.Admin,
				},
				{
					text: "Sync Logs",
					route: SparkMemberRoutes.VIEW_SYNC_LOGS,
					icon: <SyncIcon />,
					neededRole: UserRoles.Admin,
				},
			],
		},
	],
};
