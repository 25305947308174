import { lazy } from "react";
import { RouteElement } from "../routeHelpers";
import { PublicRoutes, StudentRoutes } from "../routes";

const ConfirmAccount = lazy(() => import(`features/authentication/Register/ConfirmAccount`));
const SetPassword = lazy(() => import(`features/authentication/Password/SetPassword`));
const StudentProfilePage = lazy(() => import("features/dashboards/student/profile/StudentProfilePage"));
const ViewCourses = lazy(() => import(`features/dashboards/student/courses/view/ViewCourses`));
const ViewCoursePage = lazy(() => import("features/dashboards/student/courses/view/ViewCoursePage"));
const ViewAssignments = lazy(() => import("features/dashboards/student/assignments/ViewAssignments"));
const ViewAssessments = lazy(() => import("features/dashboards/student/assessments/ViewAssessments"));
const ViewNotifications = lazy(() => import(`features/dashboards/student/notifications/ViewNotifications`));
export const routes: RouteElement[] = [
	{
		path: PublicRoutes.SETPASSWORD,
		component: SetPassword,
		exact: true,
	},
	{
		path: PublicRoutes.CONFIRMACCOUNT,
		component: ConfirmAccount,
		exact: true,
	},
	{
		path: StudentRoutes.VIEW_PROFILE,
		component: StudentProfilePage,
		exact: true,
		title: "My Profile",
		type: "readonly",
	},
	{
		path: StudentRoutes.EDIT_PROFILE,
		component: StudentProfilePage,
		exact: true,
		title: "My Profile",
		type: "edit",
	},
	{
		path: StudentRoutes.VIEW_COURSES,
		component: ViewCourses,
		title: "Courses",
		exact: true,
	},
	{
		path: StudentRoutes.VIEW_COURSE_PAGE,
		component: ViewCoursePage,
		exact: true,
	},
	{
		path: StudentRoutes.VIEW_ASSIGNMENTS,
		component: ViewAssignments,
		title: "Assignments",
		exact: true,
	},
	{
		path: StudentRoutes.VIEW_ASSESSMENTS,
		component: ViewAssessments,
		title: "Reports",
		exact: true,
	},
	{
		path: StudentRoutes.VIEW_NOTIFICATIONS,
		component: ViewNotifications,
		title: "Notifications",
		exact: true,
	},
];
