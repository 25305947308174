import AssignmentsIcon from "@mui/icons-material/MenuBook";
import StudentsIcon from "@mui/icons-material/School";
import AssessmentsIcon from "@mui/icons-material/WorkspacePremium";
import { StudentRoutes } from "app/routes/routes";
import { StudentType } from "shared/constants/enums/StudentType";
import { ISidebarContent } from "shared/models/Sidebar";
import DyanmicNotificationsItem from "../NotificationsItem";

export const studentSidebarContent: ISidebarContent = {
	sections: [
		{
			name: "Management",
			items: [
				{
					text: "Courses",
					route: StudentRoutes.VIEW_COURSES,
					icon: <StudentsIcon />,
				},
				{
					text: "Assignments",
					route: StudentRoutes.VIEW_ASSIGNMENTS,
					icon: <AssignmentsIcon />,
					neededStudentTypes: [StudentType.FULL_TIME, StudentType.PART_TIME],
				},
				{
					text: "Reports",
					route: StudentRoutes.VIEW_ASSESSMENTS,
					icon: <AssessmentsIcon />,
					neededStudentTypes: [StudentType.FULL_TIME, StudentType.PART_TIME],
				},
				{
					text: "Notifications",
					route: StudentRoutes.VIEW_NOTIFICATIONS,
					icon: <DyanmicNotificationsItem />,
				},
			],
		},
	],
};
