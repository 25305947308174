import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserInfo } from "firebase/auth";
import { auth } from "index";
import { Claims, FirebaseUserData, UseUserReturn } from "shared/hooks/useUser";

export type IUserState = {
	user: UseUserReturn;
};

const INITIAL_STATE: IUserState = {
	user: { isLoggedIn: false, hasCompletedAuthenticationFlow: false },
};

export const authSlice = createSlice({
	name: "auth",
	initialState: INITIAL_STATE,
	reducers: {
		setUser: (
			state,
			{ payload }: PayloadAction<(UserInfo & Pick<FirebaseUserData, "id" | "claims">) | null>
		) => {
			if (!payload || !auth.currentUser) {
				state.user = { isLoggedIn: false, hasCompletedAuthenticationFlow: true };
			} else {
				state.user = {
					...payload,
					lastRefreshAt: auth.currentUser.reloadUserInfo.lastRefreshAt,
					isLoggedIn: true,
					hasCompletedAuthenticationFlow: true,
				};
			}
		},
		updateUserClaims: (state, action: { payload: { claims: Claims; photoURL: string } }) => {
			if (state.user.isLoggedIn)
				state.user = {
					...state.user,
					claims: action.payload.claims,
					lastRefreshAt: new Date().toJSON(),
					photoURL: action.payload.photoURL,
				};
		},
	},
});

export const { setUser, updateUserClaims } = authSlice.actions;
