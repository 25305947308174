import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { RQKeys } from "shared/react-query/globalKeys";
import { generateSASToken } from "shared/services/azureStorageService";

export const useImageWithToken = (imageURL?: string | undefined | null) => {
	const { data: token } = useQuery({
		queryKey: RQKeys.AZURE_STORAGE_SAS_TOKEN,
		queryFn: generateSASToken,
		enabled: !!imageURL,
	});

	const imageWithToken = useMemo(() => {
		if (!imageURL || !token) return undefined;
		return `${imageURL}${token}`;
	}, [token, imageURL]);

	return imageWithToken;
};
