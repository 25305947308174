import { Components } from "@mui/material";
import { themePalette } from "./palette";

declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		primary: true;
		secondary: true;
		ternary: true;
	}
}

export const components: Components = {
	MuiTypography: {
		defaultProps: {
			sx: {
				color: themePalette.palette.text.primary,
			},
		},
	},
	MuiButton: {
		variants: [
			{
				//selects all components
				props: {},
				style: {
					fontSize: "14px",
					height: "56px",
					width: "173px",
					padding: "12px 16px",
					borderRadius: 10,
					border: "none",
					cursor: "pointer",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					willChange: "backgroundColor",
					textTransform: "none",
					transition: "background ease-in-out 0.3s,border .3s",
				},
			},
			{
				props: { variant: "primary" },
				style: {
					backgroundColor: themePalette.palette.primary.main,
					color: themePalette.palette.text.primary,
					textDecoration: "none",
					"&:hover": {
						backgroundColor: themePalette.palette.secondary.main,
					},
				},
			},
			{
				props: { variant: "secondary" },
				style: {
					backgroundColor: themePalette.palette.common.white,
					color: themePalette.palette.common.black,
					textDecoration: "none",
					"&:hover": {
						background: themePalette.palette.grey[200],
					},
					"&:disabled": {
						color: themePalette.palette.text.disabled,
					},
				},
			},
			{
				props: { variant: "ternary" },
				style: {
					backgroundColor: themePalette.palette.common.white,
					color: themePalette.palette.common.black,
					border: "1px #b5b6b7 solid",
					textDecoration: "none",
					"&:hover": {
						backgroundColor: themePalette.palette.common.white,
						color: themePalette.palette.common.black,
						border: `1px ${themePalette.palette.common.black} solid`,
					},
				},
			},
		],
	},
};
