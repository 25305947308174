import { UserRoles } from "app/auth/UserRoles";
import { useMemo } from "react";
import { PermissionValues } from "shared/constants/enums/Permissions";
import { Claims, FirebaseUserData, useUser } from "./useUser";
import moment from "moment";
import { User } from "firebase/auth";
import { StudentType } from "shared/constants/enums/StudentType";

type Allowed = boolean;
export type PermissionsType = "Or" | "And";
// The usePermission hook is used whenever you want to check if the user has all the permissions in an array
// It is mostly used to display components conditionally based on the user permissions

export default function usePermission(
	neededPermissions: PermissionValues[],
	type: PermissionsType = "And"
): readonly [isAllowed: boolean] {
	const { user } = useUser();

	const isAllowed: Allowed = useMemo(() => {
		return isAuthorized(user, { neededPermissions, permissionOperator: "And" });
	}, [user, neededPermissions]);

	return [isAllowed] as const;
}
type Options = {
	neededPermissions?: PermissionValues[];
	permissionOperator?: "And" | "Or";
	neededRole?: UserRoles;
	neededStudentTypes?: StudentType[];
};
export const isAuthorized = (
	user: { isLoggedIn: false } | Pick<FirebaseUserData, "isLoggedIn" | "claims">,
	{ neededPermissions, neededRole, permissionOperator, neededStudentTypes }: Options = {
		permissionOperator: "And",
	}
) => {
	if (!user.isLoggedIn) return false;
	if (neededRole && user.claims.userRole !== neededRole) return false;
	if (neededStudentTypes) return neededStudentTypes.includes(user.claims.studentType);
	if (!neededPermissions) return true;

	if (permissionOperator == "And")
		return neededPermissions.every((p) =>
			user.claims?.permissions?.some((permission) => permission.p === p)
		);
	return neededPermissions.some((p) => user.claims?.permissions?.some((permission) => permission.p === p));
};

export function getUserClaims(user: User): Claims | null {
	try {
		return JSON.parse(user.reloadUserInfo.customAttributes);
	} catch {
		return null;
	}
}

export async function hasUserPermissionsBeenUpdated({ updatedAt }: Claims, firebaseUser: User) {
	const { issuedAtTime } = await firebaseUser.getIdTokenResult();
	//When the user permissions/role might have been updated
	return Boolean(updatedAt && moment.utc(updatedAt).isAfter(moment.utc(issuedAtTime)));
}
