import { ILanguage } from "app/lang";
import { useIntl } from "react-intl";
import { Path } from "shared/models/UtilityTypes";

const useTranslate = () => {
	const intl = useIntl();

	return (id: Path<ILanguage>) => intl.formatMessage({ id });
};
export default useTranslate;
