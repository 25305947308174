import { filterSlice } from "shared/components/Searchbar/filterSlice";
import { Filter } from "shared/models/HelperModels";
import { toastSlice, IToast } from "shared/components/Toast/toastSlice";
import { authSlice, IUserState } from "shared/components/Auth/authSlice";
import { ISelectedStudentState, selectedStudentSlice } from "shared/components/Slices/selectedStudentSlice";

// The top-level state object
export interface ApplicationState {
	toast: IToast;
	filters: Filter;
	currentUser: IUserState;
	selectedStudent: ISelectedStudentState;
}

export const reducers = {
	toast: toastSlice.reducer,
	filters: filterSlice.reducer,
	currentUser: authSlice.reducer,
	selectedStudent: selectedStudentSlice.reducer,
};
