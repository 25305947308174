import { Box, Typography } from "@mui/material";
import { theme } from "app/themes";
import { useEffect, useState } from "react";
import { useLayout } from "shared/hooks/useLayout";
import CoreButton from "../Buttons/CoreButton";
import { loadAnalytics } from "./helpers";

const CookiesAcceptance = () => {
	const [areCookiesAllowed, setAreCookiesAllowed] = useState<string | null>(
		localStorage.getItem("allowCookies")
	);
	const { isLaptop } = useLayout();

	const handleAcceptCookies = () => {
		window.localStorage.setItem("allowCookies", "true");
		setAreCookiesAllowed(window.localStorage.getItem("allowCookies"));
	};

	const handleDenyOptionalCookies = () => {
		window.localStorage.setItem("allowCookies", "false");
		setAreCookiesAllowed(window.localStorage.getItem("allowCookies"));
	};

	useEffect(() => {
		if (areCookiesAllowed === "true") {
			loadAnalytics();
		}
	}, [areCookiesAllowed]);

	return (
		<>
			{!areCookiesAllowed && (
				<Box
					sx={{
						color: "#808080",
						padding: "14px 20px",
						background: theme.palette.grey[200],
						position: "fixed",
						bottom: "0px",
						width: "100vw",
						zIndex: "20000",
						display: isLaptop ? "inline" : "flex",
						paddingInline: isLaptop ? "30px" : "50px",
					}}
				>
					<Typography
						sx={{
							fontSize: isLaptop ? "12px" : "16px",
							textAlign: isLaptop ? "justify" : "left",
							width: isLaptop ? "auto" : "inherit",
							marginBlock: "auto",
							color: "#000",
							paddingBottom: isLaptop ? "10px" : "0px",
						}}
					>
						We use cookies to enhance your browsing experience! By continuing to use our site,
						you agree to our use of cookies as described in our
						<a href="https://spark.school/cookie-policy/"> Cookie Policy </a>.
					</Typography>
					<Box sx={{ display: "flex", gap: "16px", flexDirection: isLaptop ? "column" : "row" }}>
						<CoreButton
							styles={{
								backgroundColor:theme.palette.grey[200],
								border: `1px solid ${theme.palette.text.primary}`,
								height: isLaptop ? "30px" : "50px",
								width: isLaptop ? "100%" : "",
							}}
							onClick={handleDenyOptionalCookies}
						>
							<Typography sx={{ color: theme.palette.text.primary }}>
								Deny Optional
							</Typography>
						</CoreButton>
						<CoreButton
							styles={{
								backgroundColor: theme.palette.text.primary,
								height: isLaptop ? "30px" : "50px",
								width: isLaptop ? "100%" : "",
							}}
							onClick={handleAcceptCookies}
						>
							<Typography sx={{ color: theme.palette.common.white }}>Allow All</Typography>
						</CoreButton>
					</Box>
				</Box>
			)}
		</>
	);
};

export default CookiesAcceptance;
