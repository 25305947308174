import { QueryKey, useIsFetching, useIsMutating } from "@tanstack/react-query";
import { ApplicationState } from "app/redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import Toast from "./Toast";
import { hide, show } from "./toastSlice";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/EmojiNature";
import InfoIcon from "@mui/icons-material/EmojiFoodBeverage";
import { useEffect, useRef } from "react";
import { RQKeys } from "shared/react-query/globalKeys";

type Props = {};

export default function ToastHandler({}: Props) {
	const dispatch = useDispatch();

	const RequestTimer = useRef<any>(null);
	const ShowTimer = useRef<any>(null);

	const isOpen = useSelector((state: ApplicationState) => state.toast.open);
	const toastMessage = useSelector((state: ApplicationState) => state.toast.message);
	const toastStyle = useSelector((state: ApplicationState) => state.toast.style);

	const noLoadingKeys = [RQKeys.NO_LOADING, RQKeys.INFINITE];

	const hasNoLoadingKey = (key: QueryKey) =>
		noLoadingKeys.every((noLoadingKey) => !key.includes(noLoadingKey));

	// useIsFetching returns the number of queries that your application is fetching in the background
	// useIsMutating returns the number of mutations that your application is fetching in the background
	const requestsFetching = useIsFetching({
		predicate: (query) => hasNoLoadingKey(query.queryKey),
	});
	const requestsMutating = useIsMutating({
		predicate: (mutation) => hasNoLoadingKey(mutation.options.mutationKey ?? []),
	});

	const totalRequest = requestsMutating + requestsFetching;

	const showLoading = () => {
		dispatch(
			show({
				message: "Loading...",
				style: "info",
			})
		);
	};

	const getToastIcon = () => {
		switch (toastStyle) {
			case "success":
				return <SuccessIcon style={{ fill: "#2D6925" }} />;
			case "error":
				return <ErrorIcon style={{ fill: "#833A5D" }} />;
			default:
				return <InfoIcon style={{ fill: "#2755A9" }} />;
		}
	};

	useEffect(() => {
		if (totalRequest > 0 && !isOpen) {
			RequestTimer.current = setTimeout(() => {
				showLoading();
			}, 300);
		} else {
			clearTimeout(RequestTimer.current);
			RequestTimer.current = null;
		}

		return () => {
			clearTimeout(RequestTimer.current);
		};
	}, [totalRequest]);

	useEffect(() => {
		if (isOpen == true) {
			if (ShowTimer.current) {
				clearTimeout(ShowTimer.current);
			}
			ShowTimer.current = setTimeout(() => {
				dispatch(hide());
			}, 4000); // 4 seconds is the minimum recommended time recommended by Material Design stanrds
		}
	}, [isOpen, toastStyle]);

	return (
		<Toast
			isOpen={isOpen}
			message={toastMessage}
			style={toastStyle}
			hide={() => {
				dispatch(hide());
			}}
			icon={getToastIcon()}
		/>
	);
}
