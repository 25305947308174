declare global {
	interface String {
		capitalize(dontChangeRestOfString?: boolean): string;
		camelCase(): string;
	}
}

const newPrototype = {
	...String.prototype,
	capitalize(dontChangeRestOfString = false) {
		if (!this) return "";
		return (
			this.charAt(0).toUpperCase() +
			(dontChangeRestOfString ? this.slice(1) : this.slice(1).toLowerCase())
		);
	},
	camelCase() {
		if (!this) return "";
		return `${this.charAt(0).toLowerCase() + this.slice(1)}`;
	},
};

export const setStringPrototype = () => {
	Object.setPrototypeOf(String.prototype, newPrototype);
};
