import { ReactNode, useState } from "react";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "shared/helpers/flattenJSON";
import { ILanguage } from "./ILanguage";
import { en } from "./en-EN";

type Props = { children: ReactNode };
export const LanguageProvider = ({ children }: Props) => {
	const [localizedText, setLocalizedText] = useState<ILanguage>(en);

	return (
		<IntlProvider locale={navigator.language} messages={flattenMessages(localizedText)}>
			{children}
		</IntlProvider>
	);
};
