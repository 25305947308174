import axios, { AxiosInterceptorOptions, AxiosRequestConfig } from "axios";
import { auth, history } from "index";
import { getUserClaims, hasUserPermissionsBeenUpdated } from "shared/hooks/usePermission";
import { showToast } from "shared/services/toastService";
import { handleLogout } from "./auth/authService";

const runWhen: AxiosInterceptorOptions["runWhen"] = (config) => {
	return config.baseURL === window.appConfig.BASE_API_URL;
};

export const initInterceptor = () => {
	axios.interceptors.request.use(
		async (conf: AxiosRequestConfig) => {
			const jwtToken = await auth.currentUser?.getIdToken();
			conf.headers = { Authorization: `Bearer ${jwtToken}` };
			return conf;
		},
		(error) => {
			return Promise.reject(error);
		},
		{
			runWhen,
		}
	);

	axios.interceptors.response.use(
		(next) => {
			return Promise.resolve(next);
		},
		async (error) => {
			const validResponse = Boolean(error?.response);
			if (!validResponse) return Promise.reject(error);

			const status = error?.response?.status as number;
			if (status !== 401) {
				const errors = error.response?.data?.errors;
				if (errors?.["Toast"]) showToast(errors["Toast"][0], "error");

				return Promise.reject(error);
			}
			if (!auth.currentUser) return;
			const claims = getUserClaims(auth.currentUser);
			if (claims && !(await hasUserPermissionsBeenUpdated(claims, auth.currentUser))) {
				if (history.length > 2) history.goBack();
				else handleLogout();
			}
		},
		{
			runWhen,
		}
	);
};
