import RootDashboard from "features/dashboards/RootDashboard";
import { signOut } from "firebase/auth";
import { auth } from "index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Switch, useLocation } from "react-router";
import { setUser } from "shared/components/Auth/authSlice";
import ToastHandler from "shared/components/Toast/ToastHandler";
import navigateTo from "shared/helpers/navigateTo";
import { getUserClaims, hasUserPermissionsBeenUpdated } from "shared/hooks/usePermission";
import useTranslate from "shared/hooks/useTranslate";
import { useUser } from "shared/hooks/useUser";
import { showToast } from "shared/services/toastService";
import Layout from "./Layout";
import { handleRefreshTimer } from "./auth/authService";
import {
	RenderRoutes,
	RouteElement,
	getFilteredRoutesForUser,
	getFirstValidSidebarRoutePath,
	getRoutesForRole,
} from "./routes/routeHelpers";
import { PublicRoutes } from "./routes/routes";
import CookiesAcceptance from "shared/components/Cookies/CookiesAcceptance";

export default () => {
	const { user } = useUser();
	const [userRoutes, setUserRoutes] = useState<RouteElement[]>([]);
	const dispatch = useDispatch();
	const location = useLocation();
	const translate = useTranslate();

	useEffect(() => {
		if (user.isLoggedIn) {
			handleRefreshTimer();
		}
	}, [user]);

	// Listen for changes in the user's auth state and update its routes
	useEffect(() => {
		const userRole = (user.isLoggedIn && user?.claims?.userRole) || undefined;

		getRoutesForRole(userRole).then((data) => {
			setUserRoutes(data.routes);
		});
	}, [user]);

	useEffect(() => {
		if (auth == undefined) return;
		const emailRedirectPaths = [PublicRoutes.CONFIRMACCOUNT, PublicRoutes.SETPASSWORD];
		if (emailRedirectPaths.some((path) => location.pathname.startsWith(path))) signOut(auth);

		// Set up an auth state change listener with Firebase
		const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
			// The firebase user is not authenticated
			if (!firebaseUser) {
				// Dispatch null and set current nextUser state to null
				dispatch(setUser(null));
				return;
			}
			let forceRefresh = false;
			const claims = getUserClaims(firebaseUser);

			// Log the user out if he is suspended (not active)
			if (claims?.isActive === false) {
				signOut(auth);
				return;
			}

			if (claims) {
				forceRefresh = await hasUserPermissionsBeenUpdated(claims, firebaseUser);
			}
			if (forceRefresh && claims) {
				const filteredRoutes = await getFilteredRoutesForUser({ isLoggedIn: true, claims });
				const routeRegexes = filteredRoutes.map(
					(r) => new RegExp(`^${r.replaceAll(":id", "[\\w\\d-]+")}$`)
				);
				const doesUserHavePermissionToThisRoute = routeRegexes.some((r) =>
					r.test(location.pathname)
				);
				if (!doesUserHavePermissionToThisRoute) {
					navigateTo(getFirstValidSidebarRoutePath({ isLoggedIn: true, claims }));
					showToast(translate("toast.permissionRevoked"), "error");
				}
			}

			const tokenResult = await firebaseUser.getIdTokenResult(forceRefresh);
			const { id, firstName, lastName, permissions, userRole, updatedAt, studentType, isActive } =
				tokenResult.claims as Record<string, any>;
			const { displayName, email, phoneNumber, photoURL, providerId, uid } = firebaseUser;
			// Update the user's information in the redux state
			dispatch(
				setUser({
					displayName,
					email,
					phoneNumber,
					photoURL,
					providerId,
					uid,
					id,
					claims: {
						firstName,
						lastName,
						permissions,
						userRole,
						updatedAt,
						studentType,
						isActive,
					},
				})
			);
		});

		return () => {
			unsubscribe();
		};
	}, [auth]);

	if (!user.hasCompletedAuthenticationFlow) return null;

	return (
		<Layout>
			<Switch>
				{user?.isLoggedIn ? <RootDashboard routes={userRoutes} /> : RenderRoutes(userRoutes)}
			</Switch>
			<ToastHandler />
			<CookiesAcceptance />
		</Layout>
	);
};
