import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "index";
import { useEffect, useState } from "react";
import { useUser } from "./useUser";

export const useNotifications = () => {
	const [unreadNotifications, setUnreadNotifications] = useState(0);
	const { user } = useUser();

	useEffect(() => {
		if (!user.isLoggedIn) return;

		const userDocRef = doc(firestore, "users", user.id);

		const getUnreadNotificationsCount = async () => {
			const docSnap = await getDoc(userDocRef);

			if (docSnap.exists()) {
				const unreadNotifications = docSnap.data().unreadNotifications;
				setUnreadNotifications(unreadNotifications);
			}
		};

		getUnreadNotificationsCount();

		const unsubscribe = onSnapshot(userDocRef, (data) => {
			getUnreadNotificationsCount();
		});

		return () => {
			unsubscribe();
		};
	}, []);

	return { unreadNotifications };
};
