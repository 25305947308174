import { JSONString } from "shared/models/HelperModels";

declare global {
	interface JSON {
		parse<T>(text: JSONString<T>): T;
		stringify<T>(value: T): JSONString<T>;
		tryParse<T>(text: JSONString<T>): T | null;
		trySafeParse<T>(text: JSONString<T>): Partial<T> | null;
	}
}

export const setJSONPrototype = () => {
	Object.defineProperty(JSON, "tryParse", {
		value: function (value: string) {
			try {
				return JSON.parse(value);
			} catch {
				return null;
			}
		},
	});
	Object.defineProperty(JSON, "trySafeParse", {
		value: function (value: string) {
			try {
				return JSON.parse(value);
			} catch {
				return null;
			}
		},
	});
};
