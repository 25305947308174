import { List, ListItem, ListItemIcon } from "@mui/material";
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import Logo from "shared/assets/svgs/Logo";
import navigateTo from "shared/helpers/navigateTo";
import { getRoleDisplayName } from "shared/helpers/roleDisplayNameHelper";
import { isAuthorized } from "shared/hooks/usePermission";
import { useUser } from "shared/hooks/useUser";
import { ISidebarItem, ISidebarSection } from "shared/models/Sidebar";
import Sidebar from "./Sidebar";
import { useStyles } from "./SidebarStyles";
import UserMenu from "./UserMenu/UserMenu";
import SidebarLogo from "shared/assets/svgs/SidebarLogo";

type Props = {
	sections: ISidebarSection[];
	hideSidebar: boolean;
};

export default function SidebarHandler({ sections, hideSidebar }: Props) {
	const { user } = useUser();
	const [open, setOpen] = useState(true);
	const classes = useStyles({ hideSidebar, open })();
	const filterForItems = ({ neededPermissions, neededRole, neededStudentTypes }: ISidebarItem) =>
		(!neededPermissions && !neededRole && !neededStudentTypes) ||
		isAuthorized(user, { neededPermissions, neededRole, permissionOperator: "Or", neededStudentTypes });

	const renderMenuItems = (items: ISidebarItem[]) => (
		<List>
			{items.filter(filterForItems).map((item, index) => (
				<ListItem
					button
					key={`nav-item-${item.text}-${index}`}
					onClick={() => {
						if (location.pathname !== item.route) navigateTo(item.route);
					}}
					className={clsx(classes.listItem, {
						[classes.listItemActive]: location.pathname === item.route,
					})}
					data-test-id={`sidebar-item-${item.text}`}
				>
					<ListItemIcon
						className={clsx(classes.itemIcon, {
							[classes.itemIconActive]: location.pathname === item.route,
						})}
					>
						{item.icon}
					</ListItemIcon>
					<div className={classes.itemLabel}>{item.text}</div>
				</ListItem>
			))}
			<span className={classes.currentIndicator} />
		</List>
	);

	const content = (
		<>
			<List className={classes.topList}>
				<div className={classes.logoContainer}>
					<SidebarLogo className={classes.logoImage} />
				</div>
				{sections
					.map((section) => ({ ...section, items: section.items.filter(filterForItems) }))
					.filter((section) => section.items.length > 0)
					.map((section: ISidebarSection) => (
						<Fragment key={`sidebar-${section.name}`}>
							<div className={classes.sectionHeader}>{section.name}</div>
							{renderMenuItems(section.items)}
						</Fragment>
					))}
			</List>
			{user.isLoggedIn && (
				<UserMenu
					username={user.claims?.firstName + " " + user.claims?.lastName}
					role={getRoleDisplayName(user.claims?.userRole)}
					photoUrl={user.photoURL}
				/>
			)}
		</>
	);

	return (
		<Sidebar content={content} open={open} hideSidebar={hideSidebar} onClose={() => setOpen(false)} />
	);
}
