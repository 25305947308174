import { Box, IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ListIcon from "@mui/icons-material/List";
import { theme } from "app/themes";
import { useState } from "react";
import MobileMenuLogo from "shared/assets/svgs/MobileMenuLogo";
import MenuNavigation from "./MenuNavigation";
import { ISidebarSection } from "shared/models/Sidebar";

type MenuNavigationFabTypes = {
	sections: ISidebarSection[];
};

const MobileMenu = ({ sections }: MenuNavigationFabTypes) => {
	const classes = useStyles({});
	const [openMenuPageNavigation, setOpenMenuPageNavigation] = useState<boolean>(false);

    const handleClickIconButton = () => {
        if(openMenuPageNavigation) setOpenMenuPageNavigation(false);
        else setOpenMenuPageNavigation(true);
     };

	return (
		<Box className={classes.container}>
			<MobileMenuLogo />
			<IconButton onClick={() => handleClickIconButton()}>
				<ListIcon className={classes.icon} />
			</IconButton>
			{openMenuPageNavigation && (
				<MenuNavigation
					open={openMenuPageNavigation}
					sections={sections}
					setOpen={setOpenMenuPageNavigation}
				/>
			)}
		</Box>
	);
};

const useStyles = makeStyles(({ palette }: Theme) => ({
	container: {
		background: "#fff",
		display: "flex",
		gap: "8px",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		position: "sticky",
		padding: "3px 10px 3px 10px",
		boxShadow: "0px 2px 4px #605f5f3b",
		zIndex: 1000,
	},
	icon: { fontSize: 35, fill: palette.text.primary },
}));

export default MobileMenu;
