import { Button } from "@mui/material";
import { theme } from "app/themes";
import * as React from "react";
import { ButtonHTMLAttributes, CSSProperties, ReactElement } from "react";

type Props = {
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	styles?: CSSProperties;
	type?: ButtonHTMLAttributes<any>["type"];
	variant?: "primary" | "secondary" | "ternary";
	disabled?: boolean;
	className?: string;
} & (TextProps | ChildrenProps);
type TextProps = {
	text: string;
	children?: never;
};
type ChildrenProps = {
	children: React.ReactNode;
	text?: never;
};

export default function CoreButton({
	type = "button",
	variant = "primary",
	disabled,
	text,
	children,
	className,
	...props
}: Props): ReactElement {
	const lowercaseLabel = !text ? "" : text.split(" ").join("").toLowerCase();

	return (
		<Button
			data-test-id={`primaryButton-${lowercaseLabel}`}
			style={{
				...props.styles,
				color: disabled ? "#00000042" : theme.palette.text.primary,
			}}
			className={className}
			onClick={props.onClick}
			type={type}
			disabled={disabled}
			//@ts-ignore
			variant={variant}
		>
			{text ?? children}
		</Button>
	);
}
