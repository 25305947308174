import { hide, show, ToastStyle } from "../components/Toast/toastSlice";
import store from "index";

export const showToast = (message: string, style: ToastStyle = "success") => {
	store.dispatch(show({ message: message, style }));
};

export const hideToast = () => {
	store.dispatch(hide());
};
