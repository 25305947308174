import CloseIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import { UserRoles } from "app/auth/UserRoles";
import { RenderRoutes, RouteElement, getFirstValidSidebarRoutePath } from "app/routes/routeHelpers";
import { SparkMemberRoutes, StudentRoutes } from "app/routes/routes";
import { theme } from "app/themes";
import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { Redirect, matchPath, useLocation } from "react-router";
import MobileMenu from "shared/components/MobileMenu/MobileMenu";
import { legalGuardianSidebarContent } from "shared/components/Sidebar/MenuItemsByRole/legalguardian";
import { sparkMemberSidebarContent } from "shared/components/Sidebar/MenuItemsByRole/sparkmember";
import { studentSidebarContent } from "shared/components/Sidebar/MenuItemsByRole/student";
import SidebarHandler from "shared/components/Sidebar/SidebarHandler";
import { useStyles } from "shared/components/Sidebar/SidebarStyles";
import { IRoute } from "shared/helpers/navigateTo";
import { useLayout } from "shared/hooks/useLayout";
import { useUser } from "shared/hooks/useUser";
import { ISidebarContent } from "shared/models/Sidebar";

interface Props {
	routes: RouteElement[];
}

export const getContentByRole = (role: UserRoles): ISidebarContent | null => {
	switch (role) {
		case UserRoles.Admin:
		case UserRoles.SparkMember:
			return sparkMemberSidebarContent;
		case UserRoles.Student:
			return studentSidebarContent;
		case UserRoles.LegalGuardian:
			return legalGuardianSidebarContent;
		default:
			return null;
	}
};

const hiddenSidebarRoutes: IRoute[] = [
	SparkMemberRoutes.EDIT_COURSE,
	SparkMemberRoutes.EDIT_COURSE_PAGE,
	SparkMemberRoutes.VIEW_COURSE_PAGE,
	StudentRoutes.VIEW_COURSE_PAGE,
	SparkMemberRoutes.VIEW_ASSIGNMENT_PAGE,
	SparkMemberRoutes.EDIT_CUSTOM_ASSIGNMENT,
];

export default function RootDashboard(props: Props): ReactElement {
	const [hideSidebar, setHideSidebar] = useState(false);
	const [sidebarContent, setSidebarContent] = useState<ISidebarContent | null>(null);
	const { user } = useUser();
	const redirectRoute = getFirstValidSidebarRoutePath(user);
	const location = useLocation();
	const { isLaptop } = useLayout();
	const classes = useStyles({ hideSidebar, isLaptop })();
	const [isMobileMessageOpen, setIsMobileMessageOpen] = useState(true);

	useEffect(() => {
		if (hiddenSidebarRoutes.some((route) => matchPath(location.pathname, route))) {
			setHideSidebar(true);
			return;
		}
		setHideSidebar(false);
	}, [location.pathname]);

	useEffect(() => {
		if (!user.isLoggedIn) return;
		if (user?.claims?.userRole != undefined) {
			setSidebarContent(getContentByRole(user.claims.userRole));
		}
	}, [user]);

	const handleCloseMobileMessageOpen = () => {
		setIsMobileMessageOpen(false);
	};

	return (
		<>
			<div className={classes.root}>
				<div
					style={{
						flexGrow: 1,
						display: isLaptop ? "block" : "flex",
						height: "calc(100% - 64px)",
						flexDirection: "row",
					}}
				>
					{isLaptop ? (
						!hideSidebar && <MobileMenu sections={sidebarContent?.sections ?? []} />
					) : (
						<SidebarHandler
							sections={sidebarContent?.sections ?? []}
							hideSidebar={hideSidebar}
						/>
					)}
					<main className={classes.content}>
						{RenderRoutes(props.routes)}
						{location.pathname == "/" && <Redirect to={redirectRoute} />}
						<div className={clsx(classes.overlay)} />
					</main>
				</div>
				{isLaptop && !hideSidebar && isMobileMessageOpen ? (
					<div
						style={{
							color: "#808080",
							padding: "14px 20px",
							background: theme.palette.grey[200],
							position: "fixed",
							bottom: "0px",
							width: "100vw",
							zIndex: "1000",
						}}
					>
						<CloseIcon
							sx={{
								":hover": { cursor: "pointer" },
								fill: theme.palette.primary.light,
								fontSize: "14px",
								float: "right",
							}}
							onClick={handleCloseMobileMessageOpen}
						/>
						<Typography sx={{ fontSize: "12px", textAlign: "center" }}>
							For best user experience, please use a PC.
						</Typography>
					</div>
				) : null}
			</div>
		</>
	);
}
