import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";

type Props = {
	unreadNotifications: number;
};

export default function NotificationIcon({ unreadNotifications }: Props) {
	return (
		<Badge badgeContent={unreadNotifications} color="error">
			<NotificationsIcon />
		</Badge>
	);
}
