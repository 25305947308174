import clsx from "clsx";
import { SyntheticEvent, useState } from "react";
import { useStyles } from "./UserMenuStyles";
import { Avatar, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover } from "@mui/material";
import { handleLogout } from "app/auth/authService";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";
import navigateTo from "shared/helpers/navigateTo";
import { useUser } from "shared/hooks/useUser";
import { useQueryClient } from "@tanstack/react-query";
import { theme } from "app/themes";
import { RQKeys } from "shared/react-query/globalKeys";
import { getUser } from "shared/services/userService";
import { SparkMemberRoutes, LegalGuardianRoutes } from "app/routes/routes";
import { UserRoles } from "app/auth/UserRoles";
import { getRoutesByRole } from "shared/helpers/getRoutesByRole";
import usePermission from "shared/hooks/usePermission";
import { PERMISSIONS } from "shared/constants/enums/Permissions";
import { useDispatch } from "react-redux";
import { resetSelectedStudent } from "shared/components/Slices/selectedStudentSlice";
import { useImageWithToken } from "shared/hooks/useImageWithToken";

type Props = {
	username: string;
	role: string;
	photoUrl?: string | null;
};

export default function UserMenu({ username, role, photoUrl }: Props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const client = useQueryClient();
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const { user } = useUser();
	const [havePermission] = usePermission([PERMISSIONS.Member.View]);

	const imageWithToken = useImageWithToken(photoUrl);

	if (!user.isLoggedIn) return <></>;

	const profileRoute = getRoutesByRole(user.claims.userRole).VIEW_PROFILE;

	const handleClose = (e: SyntheticEvent<HTMLDivElement>) => {
		setAnchorEl(null);
		e.stopPropagation();
	};

	const handleProfileClick = (e) => {
		handleClose(e);
		if (user.isLoggedIn) {
			if (havePermission) {
				client.prefetchQuery([...RQKeys.USER, user.id], getUser);
			}
			navigateTo(profileRoute);
		}
	};

	return (
		<div
			onClick={(e) => setAnchorEl(e.currentTarget)}
			className={clsx(classes.root, {
				[classes.rootPopoverOpen]: Boolean(anchorEl),
				[classes.rootPopoverClose]: !Boolean(anchorEl),
			})}
		>
			<div className={classes.profileImageContainer}>
				<Avatar src={imageWithToken}></Avatar>
			</div>

			<div className={classes.userCredentials}>
				<span className={classes.userName}>{username}</span>
				<span id={"userRole"} className={classes.userRole}>
					{role}
				</span>
			</div>
			<Popover
				open={Boolean(anchorEl)}
				transitionDuration={{
					enter: theme.transitions.duration.enteringScreen,
					exit: theme.transitions.duration.leavingScreen,
				}}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				elevation={0}
				className={classes.popover}
			>
				<Paper elevation={0} sx={{ width: "242px" }}>
					<MenuList>
						<MenuItem
							onClick={() => {
								dispatch(resetSelectedStudent());
								handleLogout();
							}}
						>
							<ListItemIcon>
								<ExitToApp style={{ fill: theme.palette.primary.light }} fontSize="small" />
							</ListItemIcon>
							<ListItemText
								disableTypography
								sx={{
									fontSize: "14px",
									color: theme.palette.primary.light,
									fontWeight: 500,
									fontFamily: "Worksans",
								}}
							>
								Logout
							</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleProfileClick}>
							<ListItemIcon>
								<AccountCircle
									style={{ fill: theme.palette.primary.light }}
									fontSize="small"
								/>
							</ListItemIcon>
							<ListItemText
								disableTypography
								sx={{
									fontSize: "14px",
									color: theme.palette.primary.light,
									fontWeight: 500,
									fontFamily: "Worksans",
								}}
							>
								My Profile
							</ListItemText>
						</MenuItem>
					</MenuList>
				</Paper>
			</Popover>
		</div>
	);
}
