import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import * as React from "react";
import { DRAWER_WIDTH, useStyles } from "./SidebarStyles";

interface Props {
	content: React.ReactNode;
	open: boolean;
	hideSidebar: boolean;
	onClose: () => void;
}

export default function Sidebar({ content, open, hideSidebar, onClose }: Props) {
	const theme = useTheme();
	const classes = useStyles({ hideSidebar, open })();

	return (
		<Drawer
			sx={{
				width: DRAWER_WIDTH,
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: hideSidebar ? "0px" : DRAWER_WIDTH,
					boxSizing: "border-box",
				},
			}}
			variant="persistent"
			anchor="left"
			transitionDuration={{
				enter: theme.transitions.duration.enteringScreen,
				exit: theme.transitions.duration.leavingScreen,
			}}
			SlideProps={{
				direction: "right",
			}}
			open={open}
			onClose={onClose}
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open || hideSidebar,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open || hideSidebar,
				}),
			}}
		>
			{content}
		</Drawer>
	);
}
