import { useState, Fragment, ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { ToastStyle } from "./toastSlice";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./ToastStyles";

function SlideTransition(props: any) {
	return <Slide {...props} direction="up" />;
}

type Props = {
	isOpen: boolean;
	message: string;
	style: ToastStyle;
	hide: () => void;
	icon: ReactNode;
};

const Toast = ({ isOpen, message, style, hide, icon }: Props) => {
	const classes = useStyles();

	const [state, setState] = useState<{
		Transition: any;
	}>({
		Transition: SlideTransition,
	});

	return (
		<Fragment>
			<Snackbar
				open={isOpen}
				TransitionComponent={state.Transition}
				key={state.Transition.name}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			>
				<MuiAlert
					icon={false}
					elevation={6}
					variant="filled"
					severity={style || "success"}
					classes={{
						root: classes.root,
						message: classes.message,
						filledInfo: classes.info,
						filledSuccess: classes.success,
						filledError: classes.error,
					}}
					children={
						<div className={classes.children}>
							<div className={classes.childrenText}>
								{icon}
								<span>{message}</span>
							</div>
							<CloseIcon onClick={hide} sx={{ cursor: "pointer" }} />
						</div>
					}
				/>
			</Snackbar>
		</Fragment>
	);
};

export default Toast;
