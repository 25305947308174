const loadHubspot = () => {
	var script = document.createElement("script");
	script.type = "text/javascript";
	script.id = "hs-script-loader";
	script.async = true;
	script.defer = true;
	script.src = "//js-eu1.hs-scripts.com/25708051.js";
	document.getElementsByTagName("head")[0].appendChild(script);
};

const loadHotjar = () => {
	(function (h, o, t, j, a, r) {
		h.hj =
			h.hj ||
			function () {
				(h.hj.q = h.hj.q || []).push(arguments);
			};
		h._hjSettings = { hjid: 3814488, hjsv: 6 };
		a = o.getElementsByTagName("head")[0];
		r = o.createElement("script");
		r.async = 1;
		r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
		a.appendChild(r);
	})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};

const loadGoogleAnalytics = () => {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	gtag("js", new Date());
	gtag("config", "G-EL5SP00CZM");

	var script = document.createElement("script");
	script.type = "text/javascript";
	script.async = true;
	script.src = "https://www.googletagmanager.com/gtag/js?id=G-EL5SP00CZM";
	document.getElementsByTagName("head")[0].appendChild(script);
};

const loadAnalytics = () => {
	loadHubspot();
	loadHotjar();
	loadGoogleAnalytics();
};

export { loadAnalytics };