export enum API_ENDPOINT {
	TASK = "api/Task/",
	COURSE = "api/Course/",
	LOOKUP = "api/Lookup/",
	FACULTY = "api/Faculty/",
	STUDENT = "api/Student/",
	LEGAL_GUARDIAN = "api/LegalGuardian/",
	ACCOUNT = "api/Account/",
	JOB_TITLE = "api/JobTitle/",
	USER_ACCESS = "api/UserAccess/",
	AZURE_STORAGE = "api/AzureStorage/",
	USER_EDUCATION = "api/UserEducation/",
	COURSE_CONTENT = "api/CourseContent/",
	PERMISSION_GROUP = "api/PermissionGroup/",
	EXERCISE = "api/Exercise/",
	EXERCISE_ANSWER = "api/ExerciseAnswer/",
	EXERCISE_OPTION = "api/ExerciseOption/",
	ASSIGNMENT = "api/Assignment/",
	TEACHER = "api/Teacher/",
	ASSESSMENT = "api/Assessment/",
	SYNC_LOG = "api/SyncLog/",
	GLOBAL_SETTINGS = "api/GlobalSettings/",
	NOTIFICATIONS = "api/Notifications/",
	CUSTOM_ASSIGNMENT = "api/CustomAssignment/",
	EXERCISE_GENERATION = "api/ExerciseGeneration/",
	CORRECTION_GUIDE = "api/CorrectionGuide/",
	EXERCISE_EVALUATION = "api/ExerciseEvaluation/",
	PROMPT_CONFIGURATION = "api/PromptConfiguration/",
	ANALYTICS = "api/Analytics/",
}
