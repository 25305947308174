import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Guid, GuidName } from "shared/models/HelperModels";

export type ISelectedStudentState = GuidName & {
	schoolYearId: Guid | null;
};

const INITIAL_STATE: ISelectedStudentState = {
	id: "" as Guid,
	name: "",
	schoolYearId: null,
};

export const selectedStudentSlice = createSlice({
	name: "selectedStudent",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedStudentState: (state, action: PayloadAction<Partial<ISelectedStudentState>>) => {
			return { ...state, ...action.payload };
		},
		resetSelectedStudent: () => {
			return { ...INITIAL_STATE };
		},
	},
});

export const { setSelectedStudentState, resetSelectedStudent } = selectedStudentSlice.actions;
