import { createSlice } from "@reduxjs/toolkit";

type IState = {
	keyword: string;
}

const INITIAL_STATE: IState = {
	keyword: "",
};

export const filterSlice = createSlice({
	name: "filters",
	initialState: INITIAL_STATE,
	reducers: {
		setKeyword: (state, action: { payload: string }) => {
			state.keyword = action.payload;
		},
		clearKeyword: (state, action: { payload?: never }) => {
			state.keyword = "";
		},
	},
});

export const { setKeyword, clearKeyword } = filterSlice.actions;
