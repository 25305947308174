export type UsableStudentTypes = Exclude<StudentType, StudentType.UNSPECIFIED>;
export enum StudentType {
	UNSPECIFIED = -1,
	FULL_TIME = 1,
	PART_TIME = 2,
	SELF_PACED = 3,
}

export const getStudentTypeLabel = (type: UsableStudentTypes) => {
	const mapper: Record<UsableStudentTypes, string> = {
		[StudentType.FULL_TIME]: "Full Time",
		[StudentType.PART_TIME]: "Part Time",
		[StudentType.SELF_PACED]: "Self Paced",
	};
	return mapper[type];
};

export const filterForUsableStudentTypes = (t: StudentType | string): t is UsableStudentTypes =>
	typeof t === "number" && t !== StudentType.UNSPECIFIED;
