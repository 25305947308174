// Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentsIcon from "@mui/icons-material/MenuBook";
import AssessmentsIcon from "@mui/icons-material/WorkspacePremium";

import { ISidebarContent } from "shared/models/Sidebar";
import { LegalGuardianRoutes } from "app/routes/routes";

export const legalGuardianSidebarContent: ISidebarContent = {
	sections: [
		{
			name: "Management",
			items: [
				{
					text: "Dashboard",
					route: LegalGuardianRoutes.VIEW_STUDENTS,
					icon: <DashboardIcon />,
				},
				{
					text: "Assignments",
					route: LegalGuardianRoutes.VIEW_ASSIGNMENTS,
					icon: <AssignmentsIcon />,
				},
				{
					text: "Reports",
					route: LegalGuardianRoutes.VIEW_ASSESSMENTS,
					icon: <AssessmentsIcon />,
				},
			],
		},
	],
};
