import { ILanguage } from "app/lang";

export const en: ILanguage = {
	languages: {
		romanian: "Romanian",
		english: "English",
	},
	success: {
		profileChangedSuccessfully: "",
		confirmationInviteSuccessfully: "",
		emailSentSuccessfully: "",
		imageRemovedSuccessfully: "",
	},
	errors: {
		default: "Something went wrong",
		isRequired: "",
		isInvalid: "",
		requiredField: "",
		invalidField: "",
		unique: "",
		minimumLength: "",
		maximumLength: "",
		notConfirmed: "",
		exceedsLimit: "",
		mustHaveAtLeast: "",
		characters: "",
		file: {
			extensionIvalid: "",
			sizeTooBig: "",
			nameTooLong: "",
			invalidImageType: "",
			imageTooBig: "",
		},
	},
	toast: {
		loading: "",
		failedRegister: "",
		successfullyRegistered: "",
		successfullyRemovedLegalGuardian: "Succesfully removed legal guardian.",
		emptyEmail: "",
		emptyPassword: "",
		invalidLogin: "",
		resetPasswordEmail: "",
		passwordHasBeenSet: "",
		requestNoLongerValid: "",
		invalidSalary: "",
		selectRole: "",
		selectDepartment: "",
		agreeTerms: "",
		permissionRevoked: "Your permission has been revoked for this page",
		successfullyDeleteCourseModule: "Module deleted successfully",
		failedDeleteCourseModule: "The module was not deleted",
		successfullyDeleteAssignment: "Assignment deleted successfully",
		failedDeleteAssignment: "The Assignment was not deleted",
		successfullyUpdateProfile: "Updated successfully",
		failedUpdateProfile: "Failed to update profile",
		successfullyUpdateImageProfile: "Updated successfully",
		failedUpdateImageProfile: "Failed to update profile image",
		successfullyDeleteImageProfile: "Updated successfully",
		failedDeleteImageProfile: "Failed to remove profile image",
		successfullyDeleteGrade: "Grade deleted successfully",
		failedDeleteGrade: "Failed to delete grade",
	},
	prompt: {
		unsavedChanges: "",
	},
	confirmation: {
		deleteProfilePicture: "",
		deleteModuleCourse: "Are you sure you want to delete the module ",
		deleteAssignment: "Are you sure you want to delete this assignment?",
		deleteGrade: "Are you sure you want to delete this grade?",
	},
	legalGuardiansLookup: {
		mother: "",
		father: "",
		grandMother: "",
		grnadFather: "",
		stepMother: "",
		stepFather: "",
		relative: "",
		other: "",
	},
	studentType: {
		fullTime: "",
		partTime: "",
	},
	permissionName: {
		addEditCourseComments: "",
		addEditCourse: "",
		addEditDeletedDraftCourses: "",
		viewPublishedCourses: "",
		viewArchivedCourses: "",
		viewCourseComments: "",
		viewDraftCourses: "",
		viewWithdrawnCourses: "",
		deleteCourseComments: "",
		deleteCourseContent: "",
		deleteDraftCourses: "",
		approveDraftCourses: "",
		submitAnswers: "",
		viewAnswers: "",
		viewStudentsAttendance: "",
		addEditStudentsAttendance: "",
		deleteStudentsAttendance: "",
		viewGradesMarksFeedback: "",
		addEditGradesMarksFeedback: "",
		deleteGradesMarksFeedback: "",
		addEditGroups: "",
		deleteGroups: "",
		viewGroups: "",
		addEditTasks: "",
		deleteTasks: "",
		viewTasks: "",
		addEditMembers: "",
		deleteMembers: "",
		viewMembers: "",
		addEditCareerPath: "",
		viewCareerPath: "",
		manageGamificationLevels: "",
		manageRewards: "",
		manageGamificationSkillsHierarchy: "",
		viewRewards: "",
		generateReports: "",
		viewReports: "",
		assignStudentsCourses: "",
		unassignStudentsCourses: "",
		viewStudentsCourses: "",
		addEditStudent: "",
		deleteStudent: "",
		viewStudent: "",
	},
	roleDisplayName: {
		admin: "",
		sparkMember: "",
		student: "",
		parent: "Parent",
		legalGuardian: "Legal Guardian",
	},
	toolboxItem: {
		exercise: {
			title: "",
			correct: "",
			option: "",
			addChoice: "Add Option",
			removeChoice: "",
			correctAnswer: "",
		},
		mathtex: {
			title: "",
		},
	},
	common: {
		fields: {
			password: "",
			phoneNumber: "",
			language: "",
			firstName: "First Name",
			lastName: "Last Name",
			name: "Name",
			jobTitle: "Job Title",
			color: "",
			roles: "",
			notes: "",
			date: "",
			email: "Email",
			role: "User Type",
			location: "Location",
			subjects: "Subjects",
			permissions: "Permissions",
			courseCode: "Course Code",
			versionState: "Status",
			studyLevel: "Level",
			qualification: "Qualification",
			subject: "Subject",
			courseType: "Course Type",
		},
		actions: {
			submit: "Submit",
			cancel: "",
			save: "",
			create: "",
			edit: "Edit",
			delete: "",
			close: "",
			duplicate: "",
			back: "",
			removeProfileImage: "",
			addSection: "",
			inviteMember: "Invite Member",
			invite: "Invite",
			done: "Done",
			sort: {
				alphabetically: "Sort column A to Z",
				reverseAlphabetically: "Sort column Z to A",
				oldestFirst: "Sort column by oldest first",
			},
			deleteProfilePic:
				"Are you sure you want to delete the profile picture? This action cannot be undone.",
		},
		register: {
			register: "",
			agree: "",
			alreadyRegistered: "",
			loginNow: "",
			signInWithGoogle: "",
		},
		login: {
			login: "",
			forgotPassword: "",
			rememberMe: "",
			noAccount: "",
			registerNow: "",
			continueWithGoogle: "",
		},
		logout: {
			logout: "",
		},
		terms: {
			termsAndConditions: "",
		},
		requestPassword: {
			title: "",
		},
		setPassword: {
			title: "",
			confirmPassword: "",
			passwordsDoNotMatch: "",
			tooShort: "",
			requiresDigit: "",
			requiresLower: "",
			requiresUpper: "",
			nonAlphanumeric: "",
			uniqueChars: "",
			passwordRequirements1: "",
			passwordRequirements2: "",
			passwordRequirements3: "",
			passwordRequirements4: "",
			passwordRequirements5: "",
		},
		profile: {
			profile: "",
			details: "",
			assignedOrganization: "",
			title: "",
			resetPassword: "",
			updated: "",
			unverifiedAccount: "",
		},
	},
	role: {
		superadmin: {
			users: {
				create: "",
				edit: "",
				createSuccess: "",
				editSuccess: "",
				deleteUserWarningBody: "",
				deleteUserWarningTitle: "",
			},
		},
		student: {
			noAssignedCourses: "has no course assigned yet!",
			noAssignedCoursesSelf: "You have no course assigned yet!",
			noAssignedTeachersSelf:
				"As you don't have any course assigned, you can neither assign a teacher!",
			assignCoursesFirst: "First assign an Academic Course, ",
			assignCoursesThen: "then you can also assign a teacher.",
			noTeacherAssigned: " doesn't have a teacher assigned",
		},
		member: {
			noAssignedStudents: "There are no assigned students yet!",
		},
	},
	general: {
		course: {
			singular: "Course",
			plural: "Courses",
		},
		faculty: {
			singular: "Faculty",
			plural: "Faculties",
		},
		schoolYear: {
			singular: "School Year",
			plural: "School Years",
		},
		level: {
			singular: "Level",
			plural: "Levels",
		},
		qualification: {
			singular: "Qualification",
			plural: "Qualifications",
		},
		subject: {
			singular: "Subject",
			plural: "Subjects",
		},
		teacher: {
			singular: "Teacher",
			plural: "Teachers",
		},
		status: "Status",
	},
	course: {
		editCourse: {
			editButton: "Edit Course",
			defaultView: "Default View",
			advancedView: "Advanced View",
			sharedWith: "Shared with",
			emptyMemberListMessage: "Sorry we can not find the person you are searching for.",
			emptyModulesListMessage: "Sorry we can't find the module you are searching for.",
			codeLengthValidation: "Value must contain 4 digits.",
			hasChanges: "Has Changes",
		},
	},
	exercise: {
		type: "Exercise Type",
		header: {
			placeholder: "Type your exercise request here",
		},
		answer: {
			placeholder: "Write your answer here",
			file: {
				upload: "Upload your file",
				maxFilesError: "You can only upload 10 files per exercise.",
				extensionError: "File extension not accepted.",
				sizeError: "Maximum file size is 250 MB.",
				nameLengthError: "File name is too long.",
			},
		},
		maxMark: {
			placeholder: "Set mark",
			validation: "Select a value between 1 and 100",
		},
	},
	assignment: {
		status: {
			todo: "To Do",
			submitted: "Submitted",
			reviewed: "Reviewed",
			overdue: "Overdue",
			submittedInAdvance: "In Advance",
		},
		reviewedToast: {
			message: "Assignment reviewed successfully!",
		},
	},
};
