import { DateRangeType, getDateChipRange } from "shared/constants/enums/DateRangeType";
import { PageVersionState as VersionState } from "shared/constants/versionStateList";
import { StudentFilter } from "./Filters";

export type Guid = string & { _Guid: never };
export type URL = string & { _URL: never };
export type Pixel = `${number}px`;
export type AnyString = string & { _anyString?: never };

export type Filter = {
    keyword: string;
    orderType?: OrderType;
    pageNumber?: number;
};
export type PermissionGroupMemberFilter = Filter & { memberIds: Guid[] };
export type AssignableMembersFilter = Filter & { subjectIds: Guid[]; jobTitleIds: Guid[] };
export type SparkMembersFilter = Filter & { subjectIds: Guid[]; locations: string[]; jobTitleIds: Guid[] };
export type CourseMemberFilter = Filter & { subjectIds: Guid[]; jobTitleIds: Guid[] };

export type CourseFilter = Filter & {
    facultyIds: Guid[];
    subjectIds: Guid[];
    qualificationIds: Guid[];
    versionStates: VersionState[];
};
export type AssignableCoursesFilter = Filter & {
    facultyIds: Guid[];
    subjectIds: Guid[];
    qualificationIds: Guid[];
    schoolYearId: Guid | null;
};
export type CourseModulesFilter = Filter & {
    versionStates: VersionState[];
};
export type ComponentType = "create" | "edit" | "readonly" | "student-view";

export enum OrderType {
    ASC = 1,
    DESC = 2,
    CREATION = 3,
    CREATION_DESC = 4,
}

export const PAGE_SIZE = 20;
export const INITIAL_FILTER: Filter = {
    keyword: "",
    orderType: OrderType.ASC,
};
export const INITIAL_PERMISSION_GROUP_MEMBER_FILTER: PermissionGroupMemberFilter = {
    ...INITIAL_FILTER,
    memberIds: [],
};

export const INITIAL_ASSIGNABLE_MEMBERS_FILTER: AssignableMembersFilter = {
    ...INITIAL_FILTER,
    orderType: OrderType.CREATION_DESC,
    subjectIds: [],
    jobTitleIds: [],
};

export const INITIAL_SPARK_MEMBERS_FILTER: SparkMembersFilter = {
    ...INITIAL_FILTER,
    subjectIds: [],
    locations: [],
    jobTitleIds: [],
};

export const INITIAL_COURSE_MEMBER_FILTER: CourseMemberFilter = {
    ...INITIAL_FILTER,
    subjectIds: [],
    jobTitleIds: [],
};

export const INITIAL_COURSE_MODULES_FILTER: CourseModulesFilter = {
    ...INITIAL_FILTER,
    versionStates: [],
};

export const INITIAL_STUDENT_FILTER: StudentFilter = {
    ...INITIAL_FILTER,
    orderType: OrderType.ASC,
    locations: [],
    studentTypes: [],
    subjectIds: [],
    yearGroupIds: [],
    betweenDates: getDateChipRange(DateRangeType.DEFAULT),
    dateChip: null,
    courseId: null,
    memberId: null,
    includeSelfPaced: false,
    includeInactive: false,
    pageSize: PAGE_SIZE,
};
export const INITIAL_COURSE_FILTER: CourseFilter = {
    ...INITIAL_FILTER,
    orderType: OrderType.CREATION_DESC,
    facultyIds: [],
    qualificationIds: [],
    subjectIds: [],
    versionStates: [],
};

export const INITIAL_ASSIGNABLE_COURSES_FILTER: AssignableCoursesFilter = {
    ...INITIAL_FILTER,
    orderType: OrderType.CREATION_DESC,
    facultyIds: [],
    qualificationIds: [],
    subjectIds: [],
    schoolYearId: null,
};

/**
 * Specifies what structure (T) a string has if it is JSON.parsed
 */
export type JSONString<T> = string & { _json?: T };

export type StringNum = string | number;

export type ParamsId = {
    id: Guid;
};

export type Primitive = string | number | symbol | boolean | null | undefined;

export type IFile = {
    name: string;
    url: string;
};

export type IdValue = {
    id: StringNum | Guid;
    value: number;
};
export type GuidName = {
    id: Guid;
    name: string;
};

export type IAnswerFile = {
    id: Guid;
    fileName: string;
    url: string;
    sizeInBytes: number;
    exerciseAnswerId: Guid;
    createdBy?: Guid;
};
