import { setArrayPrototype } from "./Array";
import { improveCreateElement } from "./createElement";
import { setJSONPrototype } from "./Json";
import { setStringPrototype } from "./String";

export const setPrototypes = () => {
	setArrayPrototype();
	setStringPrototype();
	improveCreateElement();
	setJSONPrototype();
};
