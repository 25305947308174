export const RQKeys = new (class {
	CONFIGURATION = ["configuration"] as const;
	get GLOBAL_SETTINGS() {
		return [...this.CONFIGURATION, "globalSettings"] as const;
	}
	get ACADEMIC_FEEDBACK_STATE() {
		return [...this.CONFIGURATION, "globalSettings", "academic_feedback_state"] as const;
	}
	get PERMISSION_GROUP() {
		return [...this.CONFIGURATION, "permissionGroup"] as const;
	}
	get PERMISSION_GROUP_MEMBERS() {
		return [...this.PERMISSION_GROUP, "members"] as const;
	}
	get PERMISSION_GROUP_SEARCH_MEMBERS() {
		return [...this.PERMISSION_GROUP_MEMBERS, "search"] as const;
	}
	get JOB_TITLES() {
		return [...this.CONFIGURATION, "jobTitle"] as const;
	}
	get MENTOR_JOB_TITLES() {
		return [...this.JOB_TITLES, "mentor"] as const;
	}
	get FACULTIES() {
		return [...this.CONFIGURATION, "faculties"] as const;
	}
	private LOOKUP = ["lookup"] as const;
	get LOOKUP_PERMISSION_GROUPS() {
		return [...this.LOOKUP, "permissionGroups"] as const;
	}
	get LOOKUP_JOB_TITLES() {
		return [...this.LOOKUP, "jobTitles"] as const;
	}
	get LOOKUP_SUBJECTS() {
		return [...this.LOOKUP, "subjects"] as const;
	}
	get LOOKUP_PUBLISHED_COURSES() {
		return [...this.LOOKUP, "courses", "published"] as const;
	}
	get LOOKUP_ASSIGNED_FOR_GRADE_COURSES() {
		return [...this.LOOKUP, "courses", "grade"] as const;
	}
	get LOOKUP_PUBLISHED_MODULES() {
		return [...this.LOOKUP, "modules", "published"] as const;
	}
	get LOOKUP_PUBLISHED_TOPICS() {
		return [...this.LOOKUP, "topics", "published"] as const;
	}
	get LOOKUP_PUBLISHED_PAGES() {
		return [...this.LOOKUP, "pages", "published"] as const;
	}
	get LOOKUP_SCHOOL_YEAR_SYSTEMS() {
		return [...this.LOOKUP, "schoolYearSystems"] as const;
	}
	/** Use this only to Invalidate/trigger refetch but not as a primary key */
	private get LOOKUP_LOCATIONS() {
		return [...this.LOOKUP, "locations"] as const;
	}
	get LOOKUP_STUDENT_LOCATIONS() {
		return [...this.LOOKUP_LOCATIONS, "student"] as const;
	}
	get LOOKUP_SPARKMEBER_LOCATIONS() {
		return [...this.LOOKUP_LOCATIONS, "sparkmember"] as const;
	}
	get LOOKUP_STUDY_LEVELS() {
		return [...this.LOOKUP, "studylevels"] as const;
	}
	get LOOKUP_YEAR_GROUPS() {
		return [...this.LOOKUP, "yearGroups"] as const;
	}
	get LOOKUP_QUALIFICATIONS() {
		return [...this.LOOKUP, "qualifications"] as const;
	}
	INFINITE = "infinite" as const;
	NO_LOADING = "no_loading" as const;
	get USERS() {
		return [this.INFINITE, "users"] as const;
	}
	get STUDENTS() {
		return [this.INFINITE, "students"] as const;
	}
	get COURSES() {
		return [this.INFINITE, "courses"] as const;
	}
	get COURSES_MODULES() {
		return ["coursesModules"] as const;
	}
	get COURSE_PAGES() {
		return ["coursePages"] as const;
	}
	get COURSE_ASSIGNABLE_MEMBERS() {
		return [this.INFINITE, "courseAssignableMembers"] as const;
	}
	get COURSE_ASSIGNED_MEMBERS() {
		return [this.INFINITE, "courseAssignedMembers"] as const;
	}
	get COURSE_ADVANCEDVIEW_MEMBERS() {
		return [this.INFINITE, "courseAdvancedViewMembers"] as const;
	}
	get STUDENT_ASSIGNABLE_COURSES() {
		return [this.INFINITE, "studentAssignableCourses"] as const;
	}
	get STUDENTS_ASSIGNED_TO_TEACHER() {
		return [this.INFINITE, "studentsAssignedToTeacher"] as const;
	}
	get STUDENTS_ASSIGNED_TO_LEGAL_GUARDIAN() {
		return [this.INFINITE, "studentsAssignedToLegalGuardian"] as const;
	}
	get STUDENTS_ASSIGNED_TO_TEACHER_BY_COURSE() {
		return [this.INFINITE, "studentsAssignedToTeacherByCourse"] as const;
	}
	get STUDENTS_ALLOCATED_FOR_TEACHER() {
		return ["studentsAllocatedForTeacher"] as const;
	}
	get STUDENTS_ALLOCATED_FOR_MENTOR() {
		return ["studentsAllocatedForMentor"] as const;
	}
	get STUDENT_COURSE_ASSIGNMENTS() {
		return [this.INFINITE, "studentCourseAssignments"] as const;
	}
	get STUDENT_COURSE_ASSIGNMENTS_AS_STUDENT() {
		return [this.INFINITE, "studentCourseAssignmentsAsStudent"] as const;
	}
	get STUDENT_COURSE_ASSIGNMENTS_AS_LEGAL_GUARDIAN() {
		return [this.INFINITE, "studentCourseAssignmentsAsLegalGuardian"] as const;
	}
	get STUDENT_ASSIGNABLE_TEACHERS() {
		return [this.INFINITE, "studentAssignableTeachers"] as const;
	}
	get STUDENT_ASSIGNABLE_MEMBERS() {
		return ["studentAssignableMembers"] as const;
	}
	USER = ["user"] as const;
	get MY_PROFILE() {
		return [...this.USER, "profile"];
	}
	get MY_COURSES() {
		return [...this.USER, "courses"];
	}
	CITIZENSHIPS = ["citizenships"] as const;
	LEGAL_GUARDIANS = ["legalGuardians"] as const;
	CURRENT_LEGAL_GUARDIAN = [...this.LEGAL_GUARDIANS, "currentUser"];
	MEMBER = ["member"] as const;
	get EDUCATION() {
		return [...this.MEMBER, "education"] as const;
	}
	get CERTIFICATES() {
		return [...this.MEMBER, "certificates"] as const;
	}
	COURSE = ["course"] as const;
	get COURSE_PAGE() {
		return [...this.COURSE, "page"] as const;
	}
	PAGE = ["page"] as const;
	get PAGE_CONTENT() {
		return [...this.PAGE, "content"] as const;
	}
	get PAGE_CONTENT_STUDENT() {
		return [...this.PAGE_CONTENT, "student"] as const;
	}
	get PAGE_CONTENT_LEGAL_GUARDIAN() {
		return [...this.PAGE_CONTENT, "legal_guardian"] as const;
	}
	get PAGE_EXERCISES() {
		return [...this.PAGE, "exercises"] as const;
	}
	get PAGE_ASSIGNMENT() {
		return [...this.PAGE, "assignment"] as const;
	}
	get EXERCISE_ANSWERS() {
		return [...this.PAGE_EXERCISES, "answers"] as const;
	}
	STUDENT = ["student"] as const;
	CURRENT_STUDENT = ["currentUser"] as const;
	STUDENT_ASSIGNED_COURSES = ["studentAssignedCourses"] as const;
	STUDENT_ASSIGNED_MENTORS = ["studentAssignedMentors"] as const;
	ASSIGNMENT = ["assignment"] as const;
	SCHOOL_YEARS = ["school_years"] as const;
	get ASSIGNMENTS_BY_STUDENT() {
		return [...this.ASSIGNMENT, "student"] as const;
	}
	get ASSIGNMENTS_AS_TEACHER() {
		return [...this.ASSIGNMENT, "asTeacher"] as const;
	}
	get ASSIGNMENTS_AS_LEGAL_GUARDIAN() {
		return [...this.ASSIGNMENT, "asLegalGuardian"] as const;
	}
	get ASSIGNMENT_AS_LEGAL_GUARDIAN() {
		return [...this.ASSIGNMENT, "assignmentAsLegalGuardian"] as const;
	}
	get ASSIGNMENT_ADD_GENERAL_COMMENT() {
		return [...this.ASSIGNMENT, "addGeneralComment", this.NO_LOADING] as const;
	}
	get ASSIGNMENT_GENERAL_COMMENTS() {
		return [this.INFINITE, ...this.ASSIGNMENT, "generalComments", this.NO_LOADING] as const;
	}
	get ASSIGNMENT_HAS_UNSEEN_COMMENTS() {
		return [...this.ASSIGNMENT, "hasUnseenComments", this.NO_LOADING] as const;
	}
	get ASSIGNMENT_HAS_UNSEEN_COMMENTS_AS_MEMBER() {
		return [...this.ASSIGNMENT_HAS_UNSEEN_COMMENTS, "member"] as const;
	}
	get ASSIGNMENT_HAS_UNSEEN_COMMENTS_AS_STUDENT() {
		return [...this.ASSIGNMENT_HAS_UNSEEN_COMMENTS, "student"] as const;
	}
	get ASSIGNMENT_HAS_UNSEEN_COMMENTS_AS_LEGAL_GUARDIAM() {
		return [...this.ASSIGNMENT_HAS_UNSEEN_COMMENTS, "legalGuardian"] as const;
	}
	get ASSIGNMENT_GENERAL_COMMENTS_AS_STUDENT() {
		return [...this.ASSIGNMENT_GENERAL_COMMENTS, "student"] as const;
	}
	get ASSIGNMENT_GENERAL_COMMENTS_AS_LEGAL_GUARDIAN() {
		return [...this.ASSIGNMENT_GENERAL_COMMENTS, "guardian"] as const;
	}
	ASSESSMENT = ["assessment"] as const;
	get ASSESSMENTS_AS_STUDENT() {
		return [...this.ASSESSMENT, "student"] as const;
	}
	get ASSESSMENTS_AS_TEACHER() {
		return [...this.ASSESSMENT, "teacher"] as const;
	}
	get ASSESSMENTS_AS_MEMBER_ON_STUDENT() {
		return [...this.ASSESSMENT, "memberOnStudent"] as const;
	}
	get SCHOOL_YEARS_BY_STUDENT() {
		return [...this.SCHOOL_YEARS, "student"] as const;
	}
	SYNC_LOGS = ["syncLogs"] as const;
	get SYNC_LOGS_ALL() {
		return [...this.SYNC_LOGS, "all"] as const;
	}
	get SYNC_LOG() {
		return [...this.SYNC_LOGS, "syncLog"] as const;
	}
	get SYNC_LOGS_INITIATE() {
		return [...this.SYNC_LOGS, "initiate"] as const;
	}
	ACADEMIC_FEEDBACKS = ["academicFeedbacks"] as const;
	get ANY_ACADEMIC_FEEDBACKS() {
		return [...this.ACADEMIC_FEEDBACKS, "any"] as const;
	}
	NOTIFICATIONS = ["notifications"] as const;
	CUSTOM_ASSIGNMENT = ["customAssignment"] as const;
	get CUSTOM_ASSIGNMENT_CONTENT() {
		return [...this.CUSTOM_ASSIGNMENT, "content"] as const;
	}
	AZURE_STORAGE = ["azureStorage"] as const;
	get AZURE_STORAGE_SAS_TOKEN() {
		return [this.NO_LOADING, ...this.AZURE_STORAGE, "sasToken"] as const;
	}
	CORRECTION_GUIDE = ["correctionGuide"] as const;
	get CORRECTION_GUIDE_BY_EXERCISE() {
		return [...this.CORRECTION_GUIDE, "exercise"] as const;
	}
	get CORRECTION_GUIDE_BY_EXERCISE_STUDENT() {
		return [...this.CORRECTION_GUIDE_BY_EXERCISE, "student"] as const;
	}
	EXERCISE_EVALUATION = ["exerciseEvaluation"] as const;
	get GENERATE_EXERCISE_EVALUATION() {
		return [...this.EXERCISE_EVALUATION, "generate", this.NO_LOADING] as const;
	}
	PROMPT_CONFIGURATION = ["promptConfiguration"] as const;
	get PROMPT_CONFIGURATION_BY_TYPE() {
		return [...this.PROMPT_CONFIGURATION, "type"] as const;
	}
	get IS_VALID_CONFIRMATION_TOKEN() {
		return ["isValidConfirmationToken"] as const;
	}
	get GENERATE_EXERCISES() {
		return ["generateExercises", this.NO_LOADING] as const;
	}
})();
