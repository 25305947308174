import { UserRoles } from "app/auth/UserRoles";
import { Route } from "react-router";
import { routes as SparkMemberComponentRoutes } from "../../app/routes/byRole/sparkmember";
import { routes as StudentRoutes } from "./byRole/student";
import { routes as LegalGuardianRoutes } from "./byRole/legalguardian";
import { IRoute } from "shared/helpers/navigateTo";
import { FirebaseUserData, UseUserReturn, useUser } from "shared/hooks/useUser";
import { ComponentType } from "shared/models/HelperModels";
import { PermissionValues } from "shared/constants/enums/Permissions";
import { isAuthorized } from "shared/hooks/usePermission";
import { Suspense } from "react";
import { getContentByRole } from "features/dashboards/RootDashboard";
import { PublicRoutes } from "./routes";
import { PickFromConditionalType } from "shared/models/UtilityTypes";

export function RenderRoutes(routes: RouteElement[]) {
	const renderedRoutes = routes.map((route, i) => <RenderRoute key={`route-${i}`} route={route} />);
	return <Suspense fallback={null}>{renderedRoutes}</Suspense>;
}

export function RenderRoute({ route }: { route: RouteElement }) {
	const { neededPermissions, neededRole } = route;
	const { user } = useUser();
	const renderComponent =
		(!neededPermissions && !neededRole) ||
		isAuthorized(user, { neededPermissions, neededRole, permissionOperator: "Or" });
	if (!renderComponent) return null;

	return (
		<Route path={route.path} exact={route.exact}>
			<route.component route={route} type={route.type} />
		</Route>
	);
}

export const getRoutesByRole = (role: UserRoles) => {
	const mapper: Record<UserRoles, RouteElement[]> = {
		[UserRoles.Admin]: SparkMemberComponentRoutes,
		[UserRoles.SparkMember]: SparkMemberComponentRoutes,
		[UserRoles.Student]: StudentRoutes,
		[UserRoles.LegalGuardian]: LegalGuardianRoutes,
	};
	return mapper[role];
};

export type RouteElement = {
	path: IRoute;
	component: React.LazyExoticComponent<React.ComponentType<any>>;
	exact?: boolean;
	title?: string;
	type?: ComponentType;
	neededPermissions?: PermissionValues[];
	neededRole?: UserRoles;
};

export const getFirstValidSidebarRoutePath = (
	user:
		| PickFromConditionalType<UseUserReturn, false, "isLoggedIn">
		| Pick<FirebaseUserData, "isLoggedIn" | "claims">
): IRoute => {
	if (!user.isLoggedIn) return PublicRoutes.AUTH;
	const defaultRoute = PublicRoutes.AUTH;

	//get sidebar content
	const content = getContentByRole(user.claims.userRole);
	if (!content) return defaultRoute;

	const filteredSections = content.sections
		.map((section) => ({
			...section,
			items: section.items.filter((item) =>
				isAuthorized(user, { ...item, permissionOperator: "Or" })
			),
		}))
		.filter((section) => section.items.length > 0);

	return filteredSections[0]?.items[0].route ?? defaultRoute;
};

export async function getRoutesForRole(role?: UserRoles): Promise<{ routes: RouteElement[] }> {
	let userRole = "none";
	if (role) userRole = UserRoles[role].toString().toLowerCase();
	if (role === UserRoles.LegalGuardian) userRole = "legalguardian";
	if (role === UserRoles.Admin) userRole = "sparkmember";
	return await import(`./byRole/${userRole}.tsx`);
}
export async function getFilteredRoutesForUser(
	user: Parameters<typeof getFirstValidSidebarRoutePath>[0]
): Promise<IRoute[]> {
	if (!user.isLoggedIn) return [];

	const { routes } = await getRoutesForRole(user.claims.userRole);

	const filteredRoutes = routes.filter(
		({ exact, neededPermissions, neededRole }) =>
			Boolean(exact) &&
			(!neededRole || neededRole == user.claims.userRole) &&
			(!neededPermissions ||
				neededPermissions.intersect(
					Object.values(user.claims.permissions).map((permission) => permission.p)
				).length)
	);

	return filteredRoutes.map((r) => r.path);
}
