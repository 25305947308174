import { history } from "../../index";
import { Guid } from "shared/models";
import { LegalGuardianRoutes, PublicRoutes, SparkMemberRoutes, StudentRoutes } from "app/routes/routes";

export type IRoute = PublicRoutes | StudentRoutes | SparkMemberRoutes | LegalGuardianRoutes;

type Props = [to: IRoute, id?: string];
export default function navigateTo(...props: Props) {
	let [destination, id] = props;
	if (id) {
		history.push(destination.replace(":id", id));
		return;
	}
	history.push(destination);
}

export const handleNavigationClick =
	(...props: Props) =>
	() =>
		navigateTo(...props);

export const goBack = () => history.goBack();

export const replaceLocation = (route: IRoute, id?: Guid) => {
	if (id) {
		const routeWithId = route.replace(":id", id);
		history.replace(routeWithId);
		return;
	}
	history.replace(route);
};
