import axios from "axios";
import { signOut } from "firebase/auth";
import { auth, history } from "index";
import jwt_decode from "jwt-decode";
import { API_ENDPOINT } from "shared/constants/api";
import { MINUTE, SECOND } from "shared/constants/time";

export const postLogin = (token: string) => {
	if (!token) return;
	const decoded = jwt_decode(token) as any;
	const tokenSubject = decoded.sub;

	const uninterceptedAxiosInstance = axios.create();
	return uninterceptedAxiosInstance.post<any>(
		`${API_ENDPOINT.USER_ACCESS}login`,
		{ tokenSubject },
		{ baseURL: window.appConfig.BASE_API_URL, headers: {
			Authorization: `Bearer ${token}`
		} }
	);
};

export const handleLogout = () => {
	signOut(auth).then(async () => {
		history.push("/");
	});
};

export const handleRefreshTimer = async () => {
	const NOW = Date.now();
	const result = await auth.currentUser?.getIdTokenResult();
	if (!result) return;
	const { expirationTime } = result;
	const MIN_DELAY = 10 * SECOND;
	const tokenDelay = new Date(expirationTime).getTime() - NOW - 3 * MINUTE;
	const delay = Math.max(MIN_DELAY, tokenDelay);

	setTimeout(async () => {
		handleRefreshTimer();
	}, delay);
};
