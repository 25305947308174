import axios, { AxiosRequestConfig } from "axios";
import { ReplaceValueOfWith, WithRequired } from "shared/models/UtilityTypes";

export class ServiceAPI {
	private PREFIX = "";
	constructor(PREFIX) {
		this.PREFIX = PREFIX;
	}

	public async get<T>(
		endpoint: string,
		//At least now its required but you can pass in the same value (undefined | AbortSignal)
		config: ReplaceValueOfWith<
			WithRequired<AxiosRequestConfig<T>, "signal">,
			"signal",
			AxiosRequestConfig["signal"]
		>
	): Promise<T> {
		const { data } = await axios.get<T>(`${this.PREFIX}${endpoint}`, {
			...config,
			baseURL: window.appConfig.BASE_API_URL,
		});
		return data;
	}

	public async post<T>(endpoint: string, body?, config?: AxiosRequestConfig<T>) {
		const { data } = await axios.post<T>(`${this.PREFIX}${endpoint}`, body, {
			...config,
			baseURL: window.appConfig.BASE_API_URL,
		});
		return data;
	}

	public async put<T>(endpoint: string, body?) {
		const { data } = await axios.put<T>(`${this.PREFIX}${endpoint}`, body, {
			baseURL: window.appConfig.BASE_API_URL,
		});
		return data;
	}

	public async patch<T>(endpoint: string, body?) {
		const { data } = await axios.patch<T>(`${this.PREFIX}${endpoint}`, body, {
			baseURL: window.appConfig.BASE_API_URL,
		});
		return data;
	}

	public async delete<T>(endpoint: string) {
		const { data } = await axios.delete<T>(`${this.PREFIX}${endpoint}`, {
			baseURL: window.appConfig.BASE_API_URL,
		});
		return data;
	}
}
