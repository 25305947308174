import moment from "moment";

export type UsableChipTypes = DateRangeType;
export type DateInterval = { startDate?: Date; endDate?: Date };
export enum DateRangeType {
	TODAY = "Today",
	THIS_WEEK = "This week",
	IN_THE_NEXT_TWO_WEEKS = "In the next two weeks",
	THIS_MONTH = "This month",
	DEFAULT = "Default",
	CUSTOM = "Custom",
}

export const getDateChipRange = (type: DateRangeType) => {
	const mapper: Record<UsableChipTypes, DateInterval> = {
		[DateRangeType.TODAY]: {
			startDate: moment().utc().startOf("day").toDate(),
			endDate: moment().utc().endOf("day").toDate(),
		},
		[DateRangeType.THIS_WEEK]: {
			startDate: moment().utc().startOf("isoWeek").toDate(),
			endDate: moment().utc().endOf("isoWeek").toDate(),
		},
		[DateRangeType.IN_THE_NEXT_TWO_WEEKS]: {
			startDate: moment().utc().toDate(),
			endDate: moment().utc().add(14, "days").toDate(),
		},
		[DateRangeType.THIS_MONTH]: {
			startDate: moment().utc().startOf("month").toDate(),
			endDate: moment().utc().endOf("month").toDate(),
		},
		[DateRangeType.DEFAULT]: {
			startDate: undefined,
			endDate: undefined,
		},
		[DateRangeType.CUSTOM]: {
			startDate: moment().utc().startOf("isoWeek").toDate(),
			endDate: moment().utc().endOf("isoWeek").toDate(),
		},
	};
	return mapper[type];
};
