import React from "react";
import { useNotifications } from "shared/hooks/useNotifications";
import { useUser } from "shared/hooks/useUser";
import NotificationIcon from "../Notifications/NotificationIcon";

type Props = {};

export default function DyanmicNotificationsItem({}: Props) {
    const { user } = useUser();
    if (!user.isLoggedIn) return null;
	const { unreadNotifications } = useNotifications();
	return <NotificationIcon unreadNotifications={unreadNotifications} />;
}
