import { createSlice } from "@reduxjs/toolkit";

export type ToastStyle = "error" | "warning" | "info" | "success";

export interface IToast {
	open: boolean;
	message: string;
	style: ToastStyle;
}

const INITIAL_STATE: IToast = {
	open: false,
	message: "",
	style: "success",
};

export const toastSlice = createSlice({
	name: "toast",
	initialState: INITIAL_STATE,
	reducers: {
		setStyle: (state, action: { payload: { style: ToastStyle } }) => {
			state.style = action.payload.style;
		},
		show: (
			state,
			action: {
				payload: { message: string | any; style?: ToastStyle };
			}
		) => {
			state.message = action.payload.message;
			state.style = action.payload.style || "success";
			state.open = true;
		},
		hide: (state) => {
			state.open = false;
		},
	},
});

export const { hide, show, setStyle } = toastSlice.actions;
