export enum SparkMemberRoutes {
	DASHBOARD = "/",
	VIEW_PROFILE = "/profile/view",
	EDIT_PROFILE = "/profile/edit",
	VIEW_USERS = "/members",
	CREATE_USER = "/members/create",
	VIEW_USER = "/members/view/:id",
	EDIT_USER = "/members/edit/:id",
	VIEW_CLASSROOMS = "/classrooms",
	VIEW_COURSES = "/courses",
	EDIT_COURSE = "/courses/details/:id",
	VIEW_COURSE_PAGE = "/courses/pages/view/:id",
	EDIT_COURSE_PAGE = "/courses/pages/edit/:id",
	EDIT_CUSTOM_ASSIGNMENT = "/custom/assignments/edit/:id",
	VIEW_ASSESSMENT = "/report",
	VIEW_PAYMENTS = "/payments",
	VIEW_DOCUMENTS = "/documents",
	VIEW_STATISTICS = "/statistics",
	VIEW_CONFIGURATIONS = "/configurations",
	CREATE_PERMISSION_GROUP = "/permission-group/create",
	EDIT_PERMISSION_GROUP = "/permission-group/edit/:id",
	VIEW_STUDENTS = "/students",
	VIEW_STUDENT = "/students/view/:id",
	EDIT_STUDENT = "/students/edit/:id",
	VIEW_ASSIGNMENTS = "/assignments",
	VIEW_ASSIGNMENT_PAGE = "/assignment/view/:id",
	VIEW_ASSESSMENTS = "/reports",
	VIEW_SYNC_LOGS = "/sync",
	VIEW_SYNC_ENTITIES = "/sync/:id",
	VIEW_NOTIFICATIONS = "/notifications",
	CUSTOM_ASSIGNMENTS_EDIT = "CUSTOM_ASSIGNMENTS_EDIT"
}

export enum StudentRoutes {
	DASHBOARD = "/",
	VIEW_PROFILE = "/profile/view",
	EDIT_PROFILE = "/profile/edit",
	VIEW_COURSES = "/courses",
	VIEW_COURSE_PAGE = "/courses/pages/view/:id",
	VIEW_ASSIGNMENTS = "/assignments",
	VIEW_ASSESSMENTS = "/reports",
	VIEW_NOTIFICATIONS = "/notifications",
}

export enum LegalGuardianRoutes {
	DASHBOARD = "/",
	PROFILE = "/profile",
	VIEW_STUDENTS = "/students",
	VIEW_STUDENT = "/students/view/:id",
	EDIT_STUDENT = "/students/edit/:id",
	VIEW_PROFILE = "/profile/view",
	EDIT_PROFILE = "/profile/edit",
	VIEW_ASSIGNMENTS = "/assignments",
	VIEW_ASSIGNMENT_PAGE = "/assignment/view/:id",
	VIEW_COURSE_PAGE = "/courses/pages/view/:id",
	VIEW_ASSESSMENTS = "/reports",
}

export enum PublicRoutes {
	AUTH = "/",
	CONFIRMACCOUNT = "/confirm-account",
	RESETPASSWORD = "/reset-password",
	SETPASSWORD = "/set-password",
}
