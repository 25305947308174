import { UserRoles } from "app/auth/UserRoles";
import { ApplicationState } from "app/redux/rootReducer";
import { UserInfo } from "firebase/auth";
import { useSelector } from "react-redux";
import { PermissionValues } from "shared/constants/enums/Permissions";
import { StudentType } from "shared/constants/enums/StudentType";
import { Guid } from "shared/models/HelperModels";
import { PermissionScope } from "shared/models/IPermissionGroup";

export type Claims = {
	userRole: UserRoles;
	permissions: { p: PermissionValues; s: PermissionScope }[];
	firstName: string;
	lastName: string;
	updatedAt: string;
	studentType: StudentType;
	isActive: boolean;
};

export type FirebaseUserData = UserInfo & {
	id: Guid;
	claims: Claims;
	isLoggedIn: true;
	hasCompletedAuthenticationFlow: boolean;
	lastRefreshAt: string;
};
export type UseUserReturn =
	| FirebaseUserData
	| { isLoggedIn: false; hasCompletedAuthenticationFlow: boolean };

export const useUser = (): { user: UseUserReturn; userId: Guid | null } => {
	const { user } = useSelector((state: ApplicationState) => state.currentUser);

	const userId = user.isLoggedIn ? user.id : null;

	return {
		user,
		userId,
	};
};
