import ObjectKeys from "shared/helpers/objectKeys";
import { GetNestedValues } from "shared/models/UtilityTypes";

// READ THIS BEFORE ADDING NEW PERMISSION https://dev.azure.com/sparkschool/Spark/_wiki/wikis/Spark%20Wiki/74/How-to-add-a-new-Permission
export const PERMISSIONS = {
	Student: {
		View: 36,
		Add: 37,
		Edit: 49,
		Delete: 38,
		GetAllAsArray,
	},
	Courses: {
		ViewDraft: 1,
		ViewPublished: 2,
		ViewWithdrawn: 4,
		Add: 50,
		Edit: 51,
		EditContent: 6,
		Publish: 52,
		UpsertCorrectAnswers: 58,
		GetAllAsArray,
	},
	Attandance: {
		Add: 20,
		Delete: 21,
		GetAllAsArray,
	},
	Grades: {
		Add: 17,
		Delete: 18,
		GetAllAsArray,
	},
	Member: {
		View: 25,
		Add: 26,
		Delete: 27,
		Edit: 48,
		EditEmail: 57,
		GetAllAsArray,
	},
	Assignments: {
		View: 53,
		Create: 54,
		Delete: 55,
		GetAllAsArray,
	},
	Assessments: {
		View: 56,
		GetAllAsArray,
	},
	GetAllAsArray,
} as const;

export const SCOPED_PERMISSIONS = [
	PERMISSIONS.Student.View,
	PERMISSIONS.Courses.ViewPublished,
	PERMISSIONS.Courses.ViewDraft,
	PERMISSIONS.Courses.ViewWithdrawn,
] as const;

function GetAllAsArray(): PermissionValues[] {
	//@ts-ignore
	const { GetAllAsArray, ...allExceptFunction } = this;
	const objects = [allExceptFunction as any];
	const array = Array<PermissionValues>();

	while (objects.length) {
		const current = objects[0];
		objects.shift(); //remove first from array
		if (typeof current == "object") {
			const deeperObjs = ObjectKeys(current)
				.map((field) => current[field])
				.filter((val) => typeof val !== "function");
			objects.push(...deeperObjs);
			continue;
		}
		if (!array.includes(current)) array.push(current);
	}
	return array;
}

export type PermissionValues = GetNestedValues<typeof PERMISSIONS, number>;
