import { ThemeProvider, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createContext } from "react";
import { MAX_LAPTOP_WIDTH, MAX_TABLET_WIDTH } from "shared/constants/sizes";
import { theme } from "./themes";

type LayoutContextProps = {
	isLaptop: boolean;
	isTablet: boolean;
};

export const LayoutContext = createContext<LayoutContextProps>({ isLaptop: false, isTablet: false });

export default (props: { children?: React.ReactNode }) => {
	const isLaptop = useMediaQuery(`(max-width:${MAX_LAPTOP_WIDTH}px)`);
	const isTablet = useMediaQuery(`(max-width:${MAX_TABLET_WIDTH}px)`);

	return (
		<LayoutContext.Provider value={{ isLaptop, isTablet }}>
			<ThemeProvider theme={theme}>
				<Grid container>
					<Grid container item>
						{props.children}
					</Grid>
				</Grid>
			</ThemeProvider>
		</LayoutContext.Provider>
	);
};
