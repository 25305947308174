import { QueryFunctionContext } from "@tanstack/react-query";
import { BaseUserFormInputs } from "features/dashboards/sparkmember/shared/UserForm";
import { API_ENDPOINT } from "shared/constants/api";
import { Guid, SparkMembersFilter } from "shared/models/HelperModels";
import { UserCapacity } from "shared/models/IUserCapacity";
import { IUserManage, IUserProfileImageInput, User } from "shared/models/users/User";
import { RQKeys } from "shared/react-query/globalKeys";
import { ServiceAPI } from "./ServiceAPI";
import { Student } from "shared/models/users/Student";

type ProfileImageUploadResponse = {
	originalURL: NonNullable<Student["profilePicture"]>;
	thumbnailURL: NonNullable<Student["thumbnailPicture"]>;
};
type CapacityList = { capacities: UserCapacity[] };

const accountAPI = new ServiceAPI(API_ENDPOINT.ACCOUNT);
const userAccessAPI = new ServiceAPI(API_ENDPOINT.USER_ACCESS);
const azureAPI = new ServiceAPI(API_ENDPOINT.AZURE_STORAGE);

//GET
export const getUsers = async ({
	queryKey: [_k1, _k2, filter],
	pageParam = 0,
	signal,
}: QueryFunctionContext<readonly [...typeof RQKeys.USERS, SparkMembersFilter], any>) =>
	accountAPI.post<User[]>(`getSparkMembers?pageNumber=${pageParam}`, filter, { signal });

export const getUser = async ({
	queryKey: [_k1, userId],
	signal,
}: QueryFunctionContext<[...typeof RQKeys.USER, Guid], any>) =>
	accountAPI.get<User>(`get/${userId}`, { signal });

//POST
export const uploadProfileImage = async ({ userId, attachment }: Required<IUserProfileImageInput>) =>
	azureAPI.post<ProfileImageUploadResponse>(`uploadProfileImage/${userId}`, attachment);

export const confirmAccount = async (model: IUserManage) =>
	userAccessAPI.post<string>("confirmAccount", model);

export const setPassword = async (model: IUserManage) => userAccessAPI.post<string>("setPassword", model);

export const sendResetPassword = async (resetData: { email: string }) =>
	userAccessAPI.post<string>("sendResetPassword", resetData);

export const sendResetPasswordStudent = async (resetData: { email: string }) =>
	userAccessAPI.post<string>("sendResetPasswordForStudent", resetData);

export const sendForgotPassword = async (resetData: { email: string }) =>
	userAccessAPI.post<string>("sendForgotPassword", resetData);

export const resendMemberInvite = async (memberData: { email: string }) =>
	userAccessAPI.post<string>("resendMemberConfirmation", memberData);

export const resendStudentInvite = async (studentData: { email: string }) =>
	userAccessAPI.post<string>("resendStudentConfirmation", studentData);

export const createMember = async (user: any) => accountAPI.post<unknown>("createMember", user);

//PUT
export const editUser = async (user: BaseUserFormInputs & CapacityList & { id: Guid }) =>
	await accountAPI.put<void>("edit", user);

export const editMyUser = async (user: CapacityList & { id: Guid }) =>
	accountAPI.put<User>("editMyUser", user);

//DELETE
export const deleteProfileImage = async (userId: Guid) =>
	await azureAPI.delete<void>(`deleteProfileImage/${userId}`);

export const deleteMember = async (model: { userId: Guid }) =>
	await accountAPI.delete<void>(`softDeleteUser/${model.userId}`);
