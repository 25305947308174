import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: "#FFFFFF",
		borderRadius: 20,
		border: `1px solid ${theme.palette.divider}`,
		display: "flex",
		alignItems: "center",
		padding: "19px 14px",
		gap: 12,
		margin: "24px 34px",
		color: "#212529",
		cursor: "pointer",
		userSelect: "none",
	},
	rootPopoverOpen: {
		boxShadow: "14px -20px 70px 14px #00000029",
		borderRadius: "1px 1px 20px 20px",
		transition: "border-radius 0.2s",
	},
	rootPopoverClose: {
		borderRadius: 20,
		transition: "border-radius 0.2s",
	},
	profileImageContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	popover: {
		"& > .MuiBackdrop-root": {
			backgroundColor: "transparent !important",
			boxShadow: "12px 14px 14px #00000029",
		},
		"& > .MuiPaper-root": {
			borderRadius: "20px 20px 1px 1px",
		},
	},
	profileImage: {
		height: "34px",
		width: "34px",
		borderRadius: "50%",
		objectFit: "cover",
	},
	userCredentials: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	userName: {
		fontSize: "14px",
		lineHeight: "17px",
		fontWeight: 700,
		maxWidth: "160px",
		wordWrap: "break-word",
		whiteSpace: "normal",
		color: theme.palette.primary.light,
	},
	userRole: {
		fontSize: "12px",
		lineHeight: "14px",
		fontFamily: "WorkSans",
		fontWeight: 400,
		color: theme.palette.primary.light,
	},
	dropdownIcon: {
		transition: ".3s transform",
	},
	rotateIcon: {
		transform: "rotateZ(180deg)",
	},
}));
