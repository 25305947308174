import { themePalette } from "./palette";

export const typography = {
	h1: {
		fontSize: "34px",
		lineHeight: "41px",
		fontFamily: "Kamber",
		fontWeight: "bold",
		color: themePalette.palette.text.primary,
	},
	h2: {
		fontSize: "24px",
		lineHeight: "41px",
		fontFamily: "Kamber",
		fontWeight: "bold",
		color: themePalette.palette.text.primary,
	},
	subtitle1: {
		fontSize: "12px",
		lineHeight: "14px",
		fontFamily: "WorkSans",
		color: themePalette.palette.text.primary,
	},
	subtitle2: {
		fontSize: "18px",
		fontFamily: "WorkSans",
	},
	h6: {
		fontSize: "24px",
		fontFamily: "WorkSans",
	},
};
