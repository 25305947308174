import { createTheme, Palette } from "@mui/material";
declare module "@mui/material" {
	interface Palette {
		courseStatus: {
			draft: string;
			published: string;
			archived: string;
			withdrawn: string;
		};
		assignmentStatus: {
			todo: string;
			submitted: string;
			reviewed: string;
			overdue: string;
			advance: string;
		};
		syncStatus: {
			queued: string;
			in_progress: string;
			successful: string;
			error: string;
			skipped: string;
		};
	}
	interface PaletteOptions {
		courseStatus?: {
			draft?: string;
			published?: string;
			archived?: string;
			withdrawn?: string;
		};
		assignmentStatus?: {
			todo?: string;
			submitted?: string;
			reviewed?: string;
			overdue?: string;
			advance?: string;
		};
		syncStatus?: {
			queued?: string;
			in_progress?: string;
			successful?: string;
			error?: string;
			skipped?: string;
		};
	}
}

const oldPalette = createTheme({
	palette: {
		primary: {
			main: "#ffd452",
			light: "#57b0ff",
		},
		secondary: {
			main: "#ffc929",
		},
		error: {
			main: "#e84f6e",
			light: "#f377a0",
		},
		warning: {
			main: "#febc39",
		},
		info: {
			main: "#4c6e9a",
			light: "#d1d7e0",
		},
		text: {
			primary: "#707070",
			secondary: "#362b55",
		},
		background: {
			default: "#f4f7fa",
		},
		common: {
			black: "#2a2d32",
		},
		divider: "#ebebeb", //currently used for borders, can be moved into "grey" if needed
		courseStatus: {
			draft: "#212529",
			published: "#77D480",
			archived: "#A9ABAC",
			withdrawn: "#FF6767",
		},
		assignmentStatus: {
			todo: "#F4DC04",
			overdue: "#FF6767",
			submitted: "#6DC1FF",
			reviewed: "#03C558",
			advance: "#A9ABAC",
		},
		syncStatus: {
			queued: "#6DC1FF",
			in_progress: "#F4DC04",
			successful: "#03C558",
			error: "#FF6767",
			skipped: "#A9ABAC",
		},
	},
	direction: "ltr",
});

export const themePalette = createTheme({
	palette: {
		primary: {
			main: "#F4DC04",
			light: "#122259",
		},
		secondary: {
			main: "#ffe70d",
		},
		error: {
			main: "#e84f6e",
			light: "#f377a0",
		},
		warning: {
			main: "#febc39",
		},
		info: {
			main: "#4c6e9a",
			light: "#d1d7e0",
		},
		text: {
			primary: "#122259",
			secondary: "#362b55",
		},
		background: {
			default: "#f4f7fa",
		},
		common: {
			black: "#2a2d32",
		},
		divider: "#ebebeb", //currently used for borders, can be moved into "grey" if needed
		courseStatus: {
			draft: "#212529",
			published: "#03C558",
			archived: "#A9ABAC",
			withdrawn: "#FF6767",
		},
		assignmentStatus: {
			todo: "#F4DC04",
			overdue: "#FF6767",
			submitted: "#6DC1FF",
			reviewed: "#03C558",
			advance: "#A9ABAC",
		},
		syncStatus: {
			queued: "#6DC1FF",
			in_progress: "#F4DC04",
			successful: "#03C558",
			error: "#FF6767",
			skipped: "#A9ABAC",
		},
	},
	direction: "ltr",
});
