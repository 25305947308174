import { UserRoles } from "app/auth/UserRoles";
import { SparkMemberRoutes, LegalGuardianRoutes, StudentRoutes } from "app/routes/routes";

export const getRoutesByRole = (role: UserRoles) => {
	switch (role) {
		case UserRoles.Admin:
			return SparkMemberRoutes;
		case UserRoles.SparkMember:
			return SparkMemberRoutes;
		case UserRoles.Student:
			return StudentRoutes;
		case UserRoles.LegalGuardian:
			return LegalGuardianRoutes;
		default:
			return SparkMemberRoutes;
	}
};
