import { UserRoles } from "app/auth/UserRoles";
import { lazy } from "react";
import { PERMISSIONS } from "shared/constants/enums/Permissions";
import { RouteElement } from "../routeHelpers";
import { PublicRoutes, SparkMemberRoutes } from "../routes";

const ConfirmAccount = lazy(() => import(`features/authentication/Register/ConfirmAccount`));
const SetPassword = lazy(() => import(`features/authentication/Password/SetPassword`));
const ViewCourses = lazy(() => import(`features/dashboards/sparkmember/Courses/View/ViewCourses`));
const EditCourse = lazy(
	() => import(`features/dashboards/sparkmember/Courses/Edit/CourseDetails/EditCourse`)
);
const EditCoursePage = lazy(
	() => import(`features/dashboards/sparkmember/Courses/Edit/CoursePage/EditCoursePage`)
);
const ViewCoursePage = lazy(
	() => import(`features/dashboards/sparkmember/Courses/View/coursePage/ViewCoursePage`)
);
const ViewConfigurations = lazy(
	() => import(`features/dashboards/sparkmember/Configurations/View/ViewConfigurations`)
);
const PermissionGroupComponent = lazy(
	() => import(`features/dashboards/sparkmember/Configurations/PermissionGroup/PermissionGroup`)
);
const ViewStudents = lazy(() => import(`features/dashboards/sparkmember/Students/View/ViewStudents`));
const CreateUser = lazy(() => import(`features/dashboards/sparkmember/Users/Create/CreateUser`));
const EditUser = lazy(() => import(`features/dashboards/sparkmember/Users/Member/MemberProfile`));
const ViewUsers = lazy(() => import(`features/dashboards/sparkmember/Users/View/ViewUsers`));
const Profile = lazy(() => import("shared/components/Other/Profile/Profile"));
const EditStudent = lazy(() => import(`features/dashboards/sparkmember/Students/Student/StudentProfile`));
const ViewAssignments = lazy(
	() => import("features/dashboards/sparkmember/Assignments/View/ViewAssignments")
);

const ViewAssignmentPage = lazy(
	() => import("features/dashboards/sparkmember/Assignments/View/ViewAssignmentPage")
);

const ViewAssessments = lazy(
	() => import(`features/dashboards/sparkmember/Assessments/View/ViewAssessments`)
);
const ViewSyncLogs = lazy(() => import(`features/dashboards/sparkmember/SyncLog/View/ViewSyncLogs`));
const ViewSyncEntities = lazy(
	() => import(`features/dashboards/sparkmember/SyncLog/View/ViewSyncEntities`)
);
const ViewNotifications = lazy(
	() => import(`features/dashboards/sparkmember/Notifications/ViewNotifications`)
);
const EditCustomAssignments = lazy(
	() => import(`features/dashboards/sparkmember/CustomAssignments/Edit/EditCustomAssignment`)
);

export const routes: RouteElement[] = [
	{
		path: PublicRoutes.SETPASSWORD,
		component: SetPassword,
		exact: true,
	},
	{
		path: PublicRoutes.CONFIRMACCOUNT,
		component: ConfirmAccount,
		exact: true,
	},
	{
		path: SparkMemberRoutes.VIEW_PROFILE,
		component: Profile,
		exact: true,
		title: "My Profile",
		type: "readonly",
	},
	{
		path: SparkMemberRoutes.EDIT_PROFILE,
		component: Profile,
		exact: true,
		title: "My Profile",
		type: "edit",
	},
	{
		path: SparkMemberRoutes.VIEW_USERS,
		component: ViewUsers,
		exact: true,
		title: "Members",
		neededPermissions: [PERMISSIONS.Member.View],
	},
	{
		path: SparkMemberRoutes.CREATE_USER,
		component: CreateUser,
		exact: true,
		neededPermissions: [PERMISSIONS.Member.Add],
	},
	{
		path: SparkMemberRoutes.EDIT_USER,
		component: EditUser,
		exact: true,
		title: "Member Details",
		neededPermissions: [PERMISSIONS.Member.Edit],
	},
	{
		path: SparkMemberRoutes.VIEW_USER,
		component: EditUser,
		exact: true,
		type: "readonly",
		title: "Member Details",
		neededPermissions: [PERMISSIONS.Member.View],
	},
	{
		path: SparkMemberRoutes.VIEW_CONFIGURATIONS,
		component: ViewConfigurations,
		exact: true,
		title: "Configurations",
		neededRole: UserRoles.Admin,
	},
	{
		path: SparkMemberRoutes.CREATE_PERMISSION_GROUP,
		component: PermissionGroupComponent,
		exact: true,
		type: "create",
		title: "Create Permission Group",
		neededRole: UserRoles.Admin,
	},
	{
		path: SparkMemberRoutes.EDIT_PERMISSION_GROUP,
		component: PermissionGroupComponent,
		exact: true,
		type: "edit",
		title: "Permission Group",
		neededRole: UserRoles.Admin,
	},
	{
		path: SparkMemberRoutes.VIEW_STUDENTS,
		component: ViewStudents,
		exact: true,
		title: "Students",
		neededPermissions: [PERMISSIONS.Student.View],
	},
	{
		path: SparkMemberRoutes.VIEW_COURSES,
		component: ViewCourses,
		exact: true,
		title: "Courses",
		neededPermissions: [PERMISSIONS.Courses.ViewPublished, PERMISSIONS.Courses.ViewDraft],
	},
	{
		path: SparkMemberRoutes.EDIT_COURSE,
		component: EditCourse,
		exact: true,
		title: "Edit Course",
		neededPermissions: [PERMISSIONS.Courses.Edit],
	},
	{
		path: SparkMemberRoutes.VIEW_COURSE_PAGE,
		component: ViewCoursePage,
		exact: true,
		type: "readonly",
		title: "View Course Page",
	},
	{
		path: SparkMemberRoutes.EDIT_COURSE_PAGE,
		component: EditCoursePage,
		exact: true,
		title: "Edit course Page",
		neededPermissions: [PERMISSIONS.Courses.EditContent],
	},
	{
		path: SparkMemberRoutes.VIEW_STUDENT,
		component: EditStudent,
		exact: true,
		type: "readonly",
		title: "Student Details",
		neededPermissions: [PERMISSIONS.Student.View],
	},
	{
		path: SparkMemberRoutes.EDIT_STUDENT,
		component: EditStudent,
		exact: true,
		title: "Student Details",
		neededPermissions: [PERMISSIONS.Student.Edit],
	},
	{
		path: SparkMemberRoutes.VIEW_ASSIGNMENTS,
		component: ViewAssignments,
		exact: true,
		title: "Assignments",
	},
	{
		path: SparkMemberRoutes.VIEW_ASSIGNMENT_PAGE,
		component: ViewAssignmentPage,
		exact: true,
		title: "View Assignment Page",
	},
	{
		path: SparkMemberRoutes.VIEW_ASSESSMENTS,
		component: ViewAssessments,
		exact: true,
		title: "Reports",
		neededPermissions: [PERMISSIONS.Assessments.View],
	},
	{
		path: SparkMemberRoutes.VIEW_SYNC_LOGS,
		component: ViewSyncLogs,
		exact: true,
		title: "Sync Logs",
		neededRole: UserRoles.Admin,
	},
	{
		path: SparkMemberRoutes.VIEW_SYNC_ENTITIES,
		component: ViewSyncEntities,
		exact: true,
		title: "Sync Entities",
		neededRole: UserRoles.Admin,
	},
	{
		path: SparkMemberRoutes.VIEW_NOTIFICATIONS,
		component: ViewNotifications,
		title: "Notifications",
		exact: true,
	},
	{
		path: SparkMemberRoutes.EDIT_CUSTOM_ASSIGNMENT,
		component: EditCustomAssignments,
		title: "Edit Custom Assignment",
		exact: true,
		neededPermissions: [PERMISSIONS.Assignments.Create],
	},
];
