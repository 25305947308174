import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
export const DRAWER_WIDTH = 314;

export type SidebarStyles = {
	hideSidebar: boolean;
	open?: boolean;
	isLaptop?: boolean;
};

export const useStyles = ({ hideSidebar, open, isLaptop }: SidebarStyles) =>
	makeStyles((theme: Theme) => ({
		root: {
			display: "flex",
			width: "100vw",
			height: "calc(var(--vh, 1vh) * 100)",
			flexDirection: "column",
			position: "relative",
		},
		appBar: {
			height: "64px",
			marginLeft: "auto",
			zIndex: theme.zIndex.drawer + 1,
			width: "100%",
			cursor: "default",
			transition: theme.transitions.create(["width"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			width: `calc(100% - ${DRAWER_WIDTH}px)`,
			transition: theme.transitions.create(["width"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		appbarButton: {
			height: "100%",
			aspectRatio: "1/1",
			marginRight: "12px",
			padding: "10px",
			minWidth: "0px",
			transition: "margin-left 0.3s ,transform 0.3s",
		},
		hide: {
			transform: "scale(0)",
			marginLeft: "-74px",
		},
		drawer: {
			whiteSpace: "nowrap",
			marginTop: "0px",
			"& > div": {
				background: theme.palette.primary.light,
			},
			"& > .MuiBackdrop-root": {
				display: "none",
			},
		},
		drawerOpen: {
			width: DRAWER_WIDTH,
			flexDirection: "column",
			justifyContent: "space-between",
			overflow: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			flexDirection: "column",
			justifyContent: "space-between",
			overflow: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: hideSidebar ? "0px" : theme.spacing(7),
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			height: "64px",
			justifyContent: open ? "center" : "start",
			width: DRAWER_WIDTH,
			padding: theme.spacing(0, 1.75),
			"& > button": {
				padding: "0px",
				"&:hover": {
					backgroundColor: "transparent",
				},
			},
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		logoContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: "32px 0px",
		},
		logoImage: {
			height: "56px",
		},
		smallLogoImage: {},
		content: {
			height: isLaptop ? (hideSidebar ? "100dvh" : `90dvh`) : "calc(var(--vh, 1vh) * 100)",
			backgroundColor: "#ffffff",
			position: "relative",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			overflow: "auto",
			padding: hideSidebar ? 0 : theme.spacing(3),
			paddingBlock: "0px",
			alignItems: "center",
			paddingBottom: hideSidebar ? 0 : "24px",
		},
		topList: {
			paddingTop: "0px",
		},
		listItem: {
			color: "#7F8285",
			opacity: 0.75,
			transition: "color .3s, opacity .3s, background-color .3s",
			padding: "8px 36px",
			height: "48px",
			gap: "14px",
		},
		listItemActive: {
			color: `${theme.palette.common.white} !important`,
			opacity: 1,
			"& ~ $currentIndicator": {
				opacity: 1,
				transition: "opacity .4s, top .3s ease-in-out",
			},
			"&:nth-child(1) ~ $currentIndicator": {
				top: "8px",
			},
			"&:nth-child(2) ~ $currentIndicator": {
				top: "56px",
			},
			"&:nth-child(3) ~ $currentIndicator": {
				top: "104px",
			},
			"&:nth-child(4) ~ $currentIndicator": {
				top: "152px",
			},
			"&:nth-child(5) ~ $currentIndicator": {
				top: "200px",
			},
			"&:nth-child(6) ~ $currentIndicator": {
				top: "248px",
			},
			"&:nth-child(7) ~ $currentIndicator": {
				top: "296px",
			},
			"&:nth-child(8) ~ $currentIndicator": {
				top: "344px",
			},
		},
		currentIndicator: {
			opacity: 0,
			background: "#212529",
			height: "48px",
			width: "4px",
			position: "absolute",
			borderRadius: "0px 4px 4px 0px",
		},
		profileButton: {
			display: "flex",
			alignItems: "start",
			width: DRAWER_WIDTH,
			justifyContent: "start",
			padding: "16px",
			gap: "16px",
			color: "#fff",
			height: () => {
				if (!open) return "56px";
				return "102px";
			},
			transition: "height .3s",
		},
		userInfo: {
			display: "flex",
			flexDirection: "column",
			alignItems: "start",
			"& > :first-child": {
				fontSize: "16px",
			},
			"& > :not(& > :first-child)": {
				opacity: 0.56,
				fontSize: "12px",
			},
		},
		menu: {
			"& .MuiBackdrop-root": {
				background: "none !important",
			},
			"& .MuiPaper-root": {
				width: DRAWER_WIDTH - 16,
				borderRadius: "24px",
				boxShadow: "12px 12px 24px #00000029",
				transform: `translate(-8px,-${(() => {
					if (!open) return "56px";
					return "102px";
				})()}) !important`,
				color: theme.palette.common.black,
				"& li": {
					gap: "8px",
					marginBlock: "4px",
				},
			},
		},
		overlay: {
			position: "fixed",
			inset: "0px",
			zIndex: -1,
			opacity: 0,
			backdropFilter: "blur(1px)",
			background: "hsl(0,0%,0%,0.3)",
			transition: "opacity .5s,z-index 0s .5s",
		},
		overlayShow: {
			opacity: 1,
			zIndex: 100,
			transition: "opacity .5s",
		},
		sectionHeader: {
			padding: "7px 34px",
			fontSize: 12,
			color: "#fff !important",
			fontFamily: "WorkSans",
			fontWeight: 500,
			lineHeight: "14px",
		},
		itemIcon: {
			color: "#7F8285 !important",
			minWidth: "unset",
			transition: "color .3s",
		},
		itemIconActive: {
			color: `${theme.palette.common.white} !important`,
		},
		itemLabel: {
			height: "20px",
		},
	}));
