import { lazy } from "react";
import { RouteElement } from "../routeHelpers";
import { LegalGuardianRoutes, PublicRoutes, SparkMemberRoutes } from "../routes";

const ConfirmAccount = lazy(() => import(`features/authentication/Register/ConfirmAccount`));
const SetPassword = lazy(() => import(`features/authentication/Password/SetPassword`));

const ViewAssignments = lazy(() => import("features/dashboards/legalguardian/Assignments/ViewAssignments"));
const ViewAssignmentPage = lazy(
	() => import("features/dashboards/legalguardian/Assignments/ViewAssignmentPage")
);

const ViewStudents = lazy(() => import(`features/dashboards/legalguardian/Students/ViewStudents`));
const StudentProfile = lazy(() => import("features/dashboards/legalguardian/Students/StudentProfilePage"));

const ViewCoursePage = lazy(() => import("features/dashboards/legalguardian/Courses/ViewCoursePage"));
const LegalGuardianProfilePage = lazy(
	() => import("features/dashboards/legalguardian/Profile/LegalGuardianProfilePage")
);

const ViewAssessments = lazy(() => import("features/dashboards/legalguardian/Assessments/ViewAssessments"));

export const routes: RouteElement[] = [
	{
		path: PublicRoutes.SETPASSWORD,
		component: SetPassword,
		exact: true,
	},
	{
		path: PublicRoutes.CONFIRMACCOUNT,
		component: ConfirmAccount,
		exact: true,
	},
	{
		path: LegalGuardianRoutes.VIEW_PROFILE,
		component: LegalGuardianProfilePage,
		exact: true,
		title: "My Profile",
		type: "readonly",
	},
	{
		path: LegalGuardianRoutes.EDIT_PROFILE,
		component: LegalGuardianProfilePage,
		exact: true,
		title: "My Profile",
		type: "edit",
	},
	{
		path: LegalGuardianRoutes.VIEW_STUDENTS,
		component: ViewStudents,
		exact: true,
		title: "Dashboard",
	},
	{
		path: LegalGuardianRoutes.VIEW_STUDENT,
		component: StudentProfile,
		exact: true,
		type: "readonly",
		title: "Student Details",
	},
	{
		path: LegalGuardianRoutes.EDIT_STUDENT,
		component: StudentProfile,
		exact: true,
		title: "Student Details",
	},
	{
		path: LegalGuardianRoutes.VIEW_COURSE_PAGE,
		component: ViewCoursePage,
		exact: true,
		type: "readonly",
		title: "View Course Page",
	},
	{
		path: LegalGuardianRoutes.VIEW_ASSIGNMENTS,
		component: ViewAssignments,
		exact: true,
		title: "Assignments",
	},
	{
		path: LegalGuardianRoutes.VIEW_ASSIGNMENT_PAGE,
		component: ViewAssignmentPage,
		exact: true,
		title: "View Assignment Page",
	},
	{
		path: LegalGuardianRoutes.VIEW_ASSESSMENTS,
		component: ViewAssessments,
		title: "Reports",
		exact: true,
	},
];
