import { Guid, Primitive, StringNum } from "shared/models/HelperModels";

declare global {
	interface Array<T> {
		distinct(): T[];
		distinctBy(predicate: (t: T) => T[keyof T]): T[];
		getIds(): T extends { id: Guid } ? Guid[] : never;
		findById(id: Guid | StringNum | undefined | null): T | undefined;
		intersect<T2 extends Primitive>(otherArr: Array<T2>): T2[];
		exclude<T2 extends Primitive>(otherArr: Array<T2>): T2[];
		getRandomElement(): T | undefined;
		last(): T;
		isEmpty(): boolean;
		max(): T extends number ? number | null : never;
	}
}

const newPrototype = {
	...Array.prototype,
	distinct() {
		return [...new Set(this)];
	},
	distinctBy<T>(predicate: (t: T) => T[keyof T]): T[] {
		const uniqueKeys = [...new Set(this.map(predicate))];
		return uniqueKeys.map((k) => this.find((t) => predicate(t) === k));
	},
	getIds(): Guid[] {
		return this.map((x) => x.id).filter((id) => Boolean(id));
	},
	findById<T>(id: Guid | null): T | undefined {
		if (id === null) return undefined;
		return this.find((x) => x.id === id);
	},
	intersect<T extends Primitive>(otherArr: Array<T>): T[] {
		return this.filter((x) => otherArr.includes(x));
	},
	exclude<T extends Primitive>(otherArr: Array<T>): T[] {
		return this.filter((x) => !otherArr.includes(x));
	},
	getRandomElement() {
		if (!this.length) return undefined;
		const randomIndex = Date.now() % this.length;
		return this[randomIndex];
	},
	last() {
		return this[this.length - 1];
	},
	isEmpty() {
		return this.length === 0;
	},
	max() {
		if (!this.length) return null;
		return Math.max(...this);
	},
};

export const setArrayPrototype = () => {
	Object.setPrototypeOf(Array.prototype, newPrototype);
};
